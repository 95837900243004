(function () {
	'use strict';

	angular.module('trakeyeApp').controller('LocationLogController', LocationLogController);

	LocationLogController.$inject = ['$filter', '$scope', 'DEFAULT_LATITUDE', 'DEFAULT_LONGITUDE', '$compile', '$state', 'LocationLog', 'ParseLinks', 'AlertService', 'pagingParams', '$stateParams', 'paginationConstants', 'LocationLogMap',
		'LocationLogMapPath', 'LocationLogVehicleMapPath', 'UsersGeoFenceData', 'UserAccount', 'GeofenceIDs', 'UserGeofences', 'AssetGroupES', 'AssetByID', '$interval', 'Report', 'AssetTypesByGeofenceID', 'VehicleGeoFenceData', 'AssetType'];

	function LocationLogController($filter, $scope, DEFAULT_LATITUDE, DEFAULT_LONGITUDE, $compile, $state, LocationLog, ParseLinks, AlertService, pagingParams, $stateParams, paginationConstants, LocationLogMap, LocationLogMapPath, LocationLogVehicleMapPath,
		UsersGeoFenceData, UserAccount, GeofenceIDs, UserGeofences, AssetGroupES, AssetByID, $interval, Report, AssetTypesByGeofenceID, VehicleGeoFenceData, AssetType) {
		var vm = this;

		vm.loadPage = loadPage;
		vm.predicate = pagingParams.predicate;
		vm.reverse = pagingParams.ascending;
		vm.transition = transition;
		vm.itemsPerPage = paginationConstants.itemsPerPage;
		vm.drawUserPath = drawUserPath;
		vm.type = "user";
		vm.pathuserid = null;
		vm.logsbystatus = [];
		vm.all = all;
		vm.active = active;
		vm.inactive = inactive;
		vm.idle = idle;
		vm.filterUsers = filterUsers;
		vm.fromDate = null;
		vm.toDate = null;
		vm.today = today;
		vm.today();
		vm.onChangeDate = onChangeDate;
		var openedInfoWindow = null;
		var latlng;
		vm.datePickerOpenStatus = {};
		vm.openCalendar = openCalendar;
		var dateFormat = 'yyyy-MM-dd';
		vm.selectedLogs = "ALL";
		var markerUserName = "";
		$scope.alllength;
		$scope.activelength;
		$scope.inactivelength;
		$scope.idlelength;
		vm.vehicles = [];

		vm.allGeofences = [];
		vm.selectedGeofenceId = null;
		vm.prevSelectedGeofenceId = null;
		var prevInfoWindow = null;

		vm.userGeofences = [];
		vm.assetFilterGeofences = [];
		vm.selectedUserGeofenceId = null;
		vm.prevSelectedUserGeofenceId = null;
		vm.responseLiveLogs = null

		vm.filterAssetsLocationOnMapView = filterAssetsLocationOnMapView;
		vm.closeMapLocationFilterModal = closeMapLocationFilterModal;
		vm.filterAssetsLoadOnMapView = filterAssetsLoadOnMapView;
		vm.closeMapLoadPathFilterModal = closeMapLoadPathFilterModal;
		vm.saveLocationFilter = saveLocationFilter;
		vm.saveLoadPathFilter = saveLoadPathFilter;
		vm.downloadLoadPath = downloadLoadPath;


		//measure tool
		var measureEnabled = false
		var polyMeasureTool, segmentDistance, midpoint
		var totalDistance = 0
		var distanceMarkers = []
		var totalDistanceMarkers = []
		var pointerMarkers = []
		var arrpolyMeasureTool = []
		vm.gotoLatitute = gotoLatitute;
		vm.gotoLatituteCity = gotoLatituteCity;
		vm.closeGotoLatitute = closeGotoLatitute;
		vm.closeGotoLatituteCity = closeGotoLatituteCity;
		vm.showZoomLocation = showZoomLocation;
		var gotoMarker = null;

		var markers = [];
		var polylines = [];

		var markersForPath = [];
		var polylinesForPath = [];

		var noDataText = null;
		vm.mapAssets = [];
		var userPathId = null;
		var userIdSelected = null;
		var latlng = null;
		vm.assetTypeValues = [];
		var gotoMarker = null;
		var isLevel1AssetsVisible = false;
		var isLevel2AssetsVisible = false;
		var isLevel3AssetsVisible = false;
		var isLevel4AssetsVisible = false;
		var isLevel5AssetsVisible = false;

		var level1AssetsMarkers = [];
		var level2AssetsMarkers = [];
		var level3AssetsMarkers = [];
		var level4AssetsMarkers = [];
		var level5AssetsMarkers = [];

		var level1AssetsPolyline = [];
		var level2AssetsPolyline = [];
		var level3AssetsPolyline = [];
		var level4AssetsPolyline = [];
		var level5AssetsPolyline = [];

		vm.assetTypeValuesPath = [];

		var isLevel1AssetsVisiblePath = false;
		var isLevel2AssetsVisiblePath = false;
		var isLevel3AssetsVisiblePath = false;
		var isLevel4AssetsVisiblePath = false;
		var isLevel5AssetsVisiblePath = false;

		var level1AssetsMarkersPath = [];
		var level2AssetsMarkersPath = [];
		var level3AssetsMarkersPath = [];
		var level4AssetsMarkersPath = [];
		var level5AssetsMarkersPath = [];

		var level1AssetsPolylinePath = [];
		var level2AssetsPolylinePath = [];
		var level3AssetsPolylinePath = [];
		var level4AssetsPolylinePath = [];
		var level5AssetsPolylinePath = [];
		vm.selecterange = selectRange;
		vm.customRange = customRange;

		// vm.loadLogsTable();
		// setTimeout(function () {
		// 	vm.updateUsers();
		// 	vm.locateUsers;
		// }, 1000);

		$scope.tab = 1;
		$scope.geofencelabel = {
			search: "Search",
			nothingSelected: "Geofence"
		}
		$scope.setTab = function (newTab) {
			$scope.tab = newTab;
			if ($scope.tab == 1) {
				//filterAssetsLocationOnMapView()
				// console.log($scope.tab);
			} else if ($scope.tab == 2) {
				vm.locateUsers;
			}
		};
		$scope.isSet = function (tabNum) {
			return $scope.tab === tabNum;
		};
		$(".select2").select2();
		$scope.geofenceMapview = function () {
			vm.selectedGeofenceId = vm.selectedGeofence[0].id
			saveLocationFilter()
		}

		$scope.geofenceTravelledPath = function () {
			vm.selectedUserGeofenceId = vm.selectedGeofencePath[0].id
			saveLoadPathFilter()
		}

		$("#searchOnMap").change(function () {
			var vehicleSelected = JSON.parse($("#searchOnMap").val());
			var vehicleLatLng = ({
				lat: vehicleSelected.latitude,
				lng: vehicleSelected.longitude
			});
			locations.map.setCenter(vehicleLatLng);
			locations.map.setZoom(20);
		});

		function myOption(zoom, center) {
			var myOptions;
			return (myOptions = {
				zoom: zoom,
				center: center,
				scaleControl: true,
				mapTypeId: google.maps.MapTypeId.ROADMAP,
				styles: [
					{
						featureType: "poi",
						elementType: "labels.icon",
						stylers: [
							{
								color: "#63666A",
							},
						],
					},
					{
						elementType: "labels.text.fill",
						stylers: [
							{
								color: "#63666A",
							},
						],
					} /* 
                    {
                        elementType: "geometry.fill",
                        stylers: [
                            {
                                color: "#EFEFEF",
                            },
                        ],
                    }, */,
					{
						featureType: "transit",
						elementType: "labels.icon",
						stylers: [
							{
								color: "#63666A",
							},
						],
					},
					{
						featureType: "road",
						elementType: "labels.icon",
						stylers: [{ visibility: "off" }],
					},
					{
						featureType: "landscape",
						stylers: [
							{
								color: "#efefef",
							},
						],
					},
					{
						featureType: "road.highway",
						elementType: "geometry",
						stylers: [
							{
								color: "#ffffff",
							},
						],
					}
				],
			});
		}

		// function geoLocation(map) {
		//     var infoWindow = new google.maps.InfoWindow();
		// 	var locationButton = document.createElement("button");
		// 	locationButton.setAttribute("title","Locate Me");
		// 	locationButton.classList.add("custom-map-control-button");
		//     var geoIcon = document.createElement("i");
		//     geoIcon.classList.add("fa");
		//     geoIcon.classList.add("fa-crosshairs");
		//     geoIcon.classList.add("geo");
		//     locationButton.appendChild(geoIcon)
		//     map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
		//         locationButton
		//     );

		//     locationButton.addEventListener("click", function () {
		//         // Try HTML5 geolocation.
		//         if (navigator.geolocation) {
		//             navigator.geolocation.getCurrentPosition(
		//                 function (position) {
		//                     var pos = {
		//                         lat: position.coords.latitude,
		//                         lng: position.coords.longitude,
		//                     };
		//                     infoWindow.setPosition(pos);
		//                     infoWindow.setContent("Location found.");	
		//                     infoWindow.open(map);
		//                     map.setCenter(pos);
		// 					map.setZoom(18);
		//                 },
		//                 function () {
		//                     handleLocationError(
		//                         true,
		//                         infoWindow,
		//                         map.getCenter()
		//                     );
		//                 },
		//                 { enableHighAccuracy: true,
		//                     timeout: 5000,
		//                     maximumAge: 0}
		//             );
		//         } else {
		//             // Browser doesn't support Geolocation
		//             handleLocationError(
		//                 false,
		//                 infoWindow,
		//                 map.getCenter()
		//             );
		//         }
		//     });

		//     function handleLocationError(
		//         browserHasGeolocation,
		//         infoWindow,
		//         pos
		//     ) {
		//         infoWindow.setPosition(pos);
		//         infoWindow.setContent(
		//             browserHasGeolocation
		//                 ? "Error: The Geolocation service failed."
		//                 : "Error: Your browser doesn't support geolocation."
		//         );
		//         infoWindow.open(map);
		//     }
		// }

		if ($scope.tab == 1) {
			UserAccount.get(function (data) {
				var isAdmin = false;
				data.authorities.forEach(function (val) {
					if (val == "ROLE_USER_ADMIN") {
						isAdmin = true;
					} else if (val == "ROLE_HOD") {
						isAdmin = true;
					}
				});
				if (isAdmin) {
					vm.adminFilter = true;
					filterUsers();
					vm.updateUsers();
					vm.locateUsers;
					filterAssetsLocationOnMapView()
				} else {
					vm.adminFilter = false;
					filterUsers();
					vm.updateUsers();
					vm.locateUsers;
					filterAssetsLocationOnMapView()
				}
				AssetType.query(
					{
						page: 0,
						size: 100,
						sort: sort(),
					},
					onSuccess,
					onError
				);
				function sort() {
					var result = [
						vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
					];
					if (vm.predicate !== "id") {
						//  result.push('id');
					}
					return result;
				}
				function onSuccess(data, headers) {
					vm.assetTypes = data;
				}
				function onError(error) {
					AlertService.error(error.data.message);
				}
			});
		}

		function getGroupAssetsFromES(geofenceIdVal, lastAssetId, mapAssetLists) {
			AssetGroupES.get(geofenceIdVal, lastAssetId, function (data) {
				vm.mapAssets = mapAssetLists;
				data.assets.forEach(function (asset) {
					var tmpAsset = {};

					tmpAsset.assetCoordinates = asset.assetCoordinates;
					tmpAsset.assetTypeName = asset.assetTypeName;
					tmpAsset.assetTypeColor = asset.assetColor;
					tmpAsset.assetTypeImagePath = asset.assetTypeImagePath;
					tmpAsset.assetTypeLayout = asset.assetTypeLayout;
					tmpAsset.id = asset.id;
					tmpAsset.name = asset.name;

					vm.mapAssets.push(tmpAsset);
				});


				if (data.assets.length < 500) {
					for (var index = 0; index < vm.assetTypeValues.length; index++) {
						var assetType = vm.assetTypeValues[index];
						var typeName = assetType.assetTypeName;
						var typeAssetsList = [];
						if (assetType.assets != null) {
							typeAssetsList = assetType.assets;
						}

						vm.mapAssets.forEach(function (asset) {
							if (typeName == asset.assetTypeName) {
								typeAssetsList.push(asset);
							}
						});
						assetType.assets = typeAssetsList;
						vm.assetTypeValues[index] = assetType;
					}
					var assetList = [];
					for (var index = 0; index < vm.assetTypeValues.length; index++) {
						if (vm.assetTypeValues[index].zoomLevel > 11 && vm.assetTypeValues[index].zoomLevel < 15) {
							vm.assetTypeValues[index].assets.forEach(function (assetVal) {
								assetList.push(assetVal);
							});
						}
					}
					drawAssets(assetList, "level1");
					if (vm.selectedLogs === "ALL") {
						vm.all();
					} else if (vm.selectedLogs === "ACTIVE") {
						vm.active();
					} else if (vm.selectedLogs === "INACTIVE") {
						vm.inactive();
					} else if (vm.selectedLogs === "IDLE") {
						vm.idle();
					}
					isLevel1AssetsVisible = true;
				}
				// drawLatestAssets(vm.mapAssets);
				if (data.lastId != null) {
					getGroupAssetsFromES(geofenceIdVal, data.lastId, vm.mapAssets);
				} else {
					if (vm.mapAssets.length > 0) {
						var assetSelected = vm.mapAssets[0];
						// AssetByID.get(assetSelected.id, function (assetVal) {
						// 	var assetLatLng = ({
						// 		lat: assetSelected.assetCoordinates[assetSelected.assetCoordinates.length - 1].latitude,
						// 		lng: assetSelected.assetCoordinates[assetSelected.assetCoordinates.length - 1].longitude
						// 	});
						// 	try {
						// 		locations.map.setCenter(assetLatLng);
						// 	} catch (error) {
						// 		console.log(error);
						// 	}
						// });
					}
				}
			});
		}

		function getGroupAssetsFromESPath(geofenceIdVal, lastAssetId, mapAssetLists) {
			AssetGroupES.get(geofenceIdVal, lastAssetId, function (data) {
				vm.mapUserAssets = mapAssetLists;
				data.assets.forEach(function (asset) {
					var tmpAsset = {};

					tmpAsset.assetCoordinates = asset.assetCoordinates;
					tmpAsset.assetTypeName = asset.assetTypeName;
					tmpAsset.assetTypeColor = asset.assetColor;
					tmpAsset.assetTypeImagePath = asset.assetTypeImagePath;
					tmpAsset.assetTypeLayout = asset.assetTypeLayout;
					tmpAsset.id = asset.id;
					tmpAsset.name = asset.name;
					vm.mapUserAssets.push(tmpAsset);
				});


				if (data.assets.length < 500) {
					for (var index = 0; index < vm.assetTypeValuesPath.length; index++) {
						var assetType = vm.assetTypeValuesPath[index];
						var typeName = assetType.assetTypeName;
						var typeAssetsList = [];
						if (assetType.assets != null) {
							typeAssetsList = assetType.assets;
						}

						vm.mapUserAssets.forEach(function (asset) {
							if (typeName == asset.assetTypeName) {
								typeAssetsList.push(asset);
							}
						});
						assetType.assets = typeAssetsList;
						vm.assetTypeValuesPath[index] = assetType;
					}
					var assetList = [];
					for (var index = 0; index < vm.assetTypeValuesPath.length; index++) {
						if (vm.assetTypeValuesPath[index].zoomLevel > 11 && vm.assetTypeValuesPath[index].zoomLevel < 15) {
							vm.assetTypeValuesPath[index].assets.forEach(function (assetVal) {
								assetList.push(assetVal);
							});
						}
					}
					drawAssetsForPath(assetList, "level1");
					// var centerlatlng = {
					// 	lat: parseFloat(assetList[assetList.length - 1].assetCoordinates[0].latitude),
					// 	lng: parseFloat(assetList[assetList.length - 1].assetCoordinates[0].longitude)
					// };
					// userPaths.map.setCenter(centerlatlng);
					// userPaths.map.setZoom(14);
					mapZoomChangeForPath();
					isLevel1AssetsVisiblePath = true;
				}
				if (data.lastId != null) {
					getGroupAssetsFromESPath(geofenceIdVal, data.lastId, vm.mapUserAssets);
				} else {
					if (vm.mapUserAssets.length > 0) {
						var assetSelected = vm.mapUserAssets[0];
						// AssetByID.get(assetSelected.id, function (assetVal) {
						// 	var assetLatLng = ({
						// 		lat: assetSelected.assetCoordinates[assetSelected.assetCoordinates.length - 1].latitude,
						// 		lng: assetSelected.assetCoordinates[assetSelected.assetCoordinates.length - 1].longitude
						// 	});
						// 	try {
						// 		userPaths.map.setCenter(assetLatLng);
						// 	} catch (error) {
						// 		console.log(error);
						// 	}
						// });
					}
				}
			});
		}

		vm.updateUsers = function () {
			vm.locateUsers(function () {
				if (vm.selectedLogs === "ALL") {
					vm.all();
				} else if (vm.selectedLogs === "ACTIVE") {
					vm.active();
				} else if (vm.selectedLogs === "INACTIVE") {
					vm.inactive();
				} else if (vm.selectedLogs === "IDLE") {
					vm.idle();
				}
			});
		}
		var updateLogs = $interval(function () {

			vm.updateUsers();
			vm.locateUsers;

			/* if (vm.selectedGeofenceId != null) {
				vm.mapAssets = [];
				getGroupLocationAssetsFromES(vm.selectedGeofenceId, null, vm.mapAssets);
			} */
		}, 180000);

		var userPaths = {};
		var locations = {};

		function onChangeDate() {
			if (vm.fromDate && vm.toDate) {
				vm.toDate.setHours(23, 59, 59, 999);
				if (vm.fromDate <= vm.toDate) {
					vm.drawUserPath(vm.pathuserid, vm.type);
					userIdSelected = vm.pathuserid;
				} else {
					//customRange()
					alert('Selected "From Date" is greater than "To Date" : Please Select Proper Date')
				}
			}
		}

		function customRange() {
			vm.fromDate = null
			vm.toDate = null
			/* var today = new Date();
			vm.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
			vm.toDate.setHours(23, 59, 59, 999); */
			vm.totalDistance = 0;
			$scope.totalDistanceTravelled = "0 kms";
			vm.inProgressDistance = 0
			userPaths.map = new google.maps.Map(document.getElementById("userPath_map_canvas"), myOption(13, userPaths.latlng));
		}
		function today() {
			var today = new Date();
			vm.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
			vm.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
		}
		var date = new Date();

		$scope.options = {
			maxDate: date.setDate(date.getDate())
		};
		function selectRange() {
			selectRangePresent()
			vm.drawUserPath(vm.pathuserid, vm.type);
			userIdSelected = vm.pathuserid;
		}
		function selectRangePresent() {
			var today = new Date();
			vm.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
			vm.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
			vm.fromDate.setHours(0, 0, 0, 0);
			vm.fromTime = vm.fromDate.getTime();
			vm.toDate.setHours(23, 59, 59, 999);
			vm.toTime = vm.toDate.getTime();
		}
		/* function loadLogsTable() {

			var fromDate = $filter('date')(vm.fromDate, dateFormat);
			var toDate = $filter('date')(vm.toDate, dateFormat);
			var data = {
				fromDate: vm.fromDate.getTime(),
				toDate: vm.toDate.getTime()
			}

			LocationLogMap.getlogsbydate(data, function (response, headers) {
				vm.links = ParseLinks.parse(headers('link'));
				vm.totalItems = headers('X-Total-Count');
				vm.queryCount = vm.totalItems;
				vm.locationLogs = response;
				vm.page = pagingParams.page;
			});
		} */

		function loadPage(page) {
			vm.page = page;
			vm.transition();
		}

		function transition() {
			$state.transitionTo($state.$current, {
				page: vm.page,
				sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
				search: vm.currentSearch
			});
		}

		$scope.$watch('login', function (searchkey, oldval) {
			if (searchkey || oldval)
				drawLatestlocations($filter('filter')($scope.locations, {
					userName: searchkey
				}));
		})

		$scope.openInfoWindow = function (e, selectedMarker) {
			e.preventDefault();
			google.maps.event.trigger(selectedMarker, 'click');
		}

		function drawUserPath(userId) {
			var data = {
				fromDate: vm.fromDate.getTime(),
				toDate: vm.toDate.getTime(),
				userId: userId
			}
			if (vm.type == "user") {
				LocationLogMapPath.getmaplogs(data, function (response) {
					vm.pathuserid = response.id;
					drawlines(response);
				});
			} else {
				LocationLogVehicleMapPath.getmaplogs(data, function (response) {
					vm.pathuserid = response.id;
					drawlines(response);
				});
			}
		}

		vm.locateUsers = function (callback) {
			LocationLogMapPath.locateUsers(function (response) {
				vm.responseLiveLogs = response.liveLogs
				$scope.trCases = response.trCases;
				$scope.locations = response.liveLogs;
				vm.vehicles = response.vehicleLocationList;
				$scope.offDutyUserlocations = response.inactiveUserLogs;
				callback();
			});
		}

		function filterUsers() {
			var isTrue
			if (vm.responseLiveLogs && vm.searchUser) {
				isTrue = vm.responseLiveLogs.find(function (item) {
					return item.login == vm.searchUser
				})
			}

			if (vm.searchUser && !isTrue) {
				LocationLogMapPath.locateUsersLogin(vm.searchUser, function (response) {
					var updatedResponse = [
						{
							"latitude": response[0].latitude,
							"longitude": response[0].longitude,
							"login": response[0].login,
							"userid": response[0].userId
						}
					]
					//$scope.locations = updatedResponse;
					callSelectedLogs(updatedResponse)
				})
			}

			callSelectedLogs($scope.locations)

		}

		function callSelectedLogs(param) {
			if (vm.selectedLogs === "ALL") {

				drawLatestlocations($filter('filter')(param, {
					'login': vm.searchUser
				}));


			} else {
				$scope.alllength = ($filter('filter')($filter('filter')(param, {
					'status': vm.selectedLogs
				}, true), {
					'login': vm.searchUser
				})).length;
				drawLatestlocations($filter('filter')($filter('filter')(param, {
					'status': vm.selectedLogs
				}, true), {
					'login': vm.searchUser
				}));
			}
		}

		function all() {
			vm.selectedLogs = "ALL";
			if ($stateParams.login == undefined) {
				try {
					$scope.alllength = ($filter('filter')($scope.locations, {
						'login': $stateParams.login
					})).length;
					$scope.activelength = ($filter('filter')($filter('filter')($scope.locations, {
						'status': "ACTIVE"
					}, true), {
						'login': vm.searchUser
					})).length;
					$scope.inactivelength = ($filter('filter')($filter('filter')($scope.locations, {
						'status': "INACTIVE"
					}, true), {
						'login': vm.searchUser
					})).length;
					$scope.idlelength = ($filter('filter')($filter('filter')($scope.locations, {
						'status': "IDLE"
					}, true), {
						'login': vm.searchUser
					})).length;
				} catch (err) {
					console.log(err);
				}
				drawLatestlocations($filter('filter')($scope.locations, {
					'login': vm.searchUser
				}));
			} else {
				try {
					$scope.alllength = ($filter('filter')($scope.locations, {
						'login': $stateParams.login
					})).length;
					$scope.activelength = ($filter('filter')($filter('filter')($scope.locations, {
						'status': "ACTIVE"
					}, true), {
						'login': vm.searchUser
					})).length;
					$scope.inactivelength = ($filter('filter')($filter('filter')($scope.locations, {
						'status': "INACTIVE"
					}, true), {
						'login': vm.searchUser
					})).length;
					$scope.idlelength = ($filter('filter')($filter('filter')($scope.locations, {
						'status': "IDLE"
					}, true), {
						'login': vm.searchUser
					})).length;
				} catch (err) {
					console.log(err);
				}
				drawLatestlocations($filter('filter')($scope.locations, {
					'login': $stateParams.login
				}));
			}
			// if (vm.selectedGeofenceId != null) {
			// 	vm.mapAssets = [];
			// 	getGroupLocationAssetsFromES(vm.selectedGeofenceId, null, vm.mapAssets, locations.map);
			// }
		}
		function active() {
			vm.selectedLogs = "ACTIVE";
			$scope.activelength = ($filter('filter')($filter('filter')($scope.locations, {
				'status': vm.selectedLogs
			}, true), {
				'login': vm.searchUser
			})).length;
			$scope.alllength = ($filter('filter')($scope.locations, {
				'login': $stateParams.login
			})).length;
			$scope.inactivelength = ($filter('filter')($filter('filter')($scope.locations, {
				'status': "INACTIVE"
			}, true), {
				'login': vm.searchUser
			})).length;
			$scope.idlelength = ($filter('filter')($filter('filter')($scope.locations, {
				'status': "IDLE"
			}, true), {
				'login': vm.searchUser
			})).length;

			drawLatestlocations($filter('filter')($filter('filter')($scope.locations, {
				'status': vm.selectedLogs
			}, true), {
				'login': vm.searchUser
			}));
			// if (vm.selectedGeofenceId != null) {
			// 	vm.mapAssets = [];
			// 	getGroupLocationAssetsFromES(vm.selectedGeofenceId, null, vm.mapAssets, locations.map);
			// }
		}

		function inactive() {
			vm.selectedLogs = "INACTIVE";
			$scope.inactivelength = ($filter('filter')($filter('filter')($scope.locations, {
				'status': vm.selectedLogs
			}, true), {
				'login': vm.searchUser
			})).length;
			$scope.alllength = ($filter('filter')($scope.locations, {
				'login': $stateParams.login
			})).length;
			$scope.activelength = ($filter('filter')($filter('filter')($scope.locations, {
				'status': "ACTIVE"
			}, true), {
				'login': vm.searchUser
			})).length;
			$scope.idlelength = ($filter('filter')($filter('filter')($scope.locations, {
				'status': "IDLE"
			}, true), {
				'login': vm.searchUser
			})).length;
			drawLatestlocations($filter('filter')($filter('filter')($scope.locations, {
				'status': vm.selectedLogs
			}, true), {
				'login': vm.searchUser
			}));
			// if (vm.selectedGeofenceId != null) {
			// 	vm.mapAssets = [];
			// 	getGroupLocationAssetsFromES(vm.selectedGeofenceId, null, vm.mapAssets, locations.map);
			// }
		}

		function idle() {
			vm.selectedLogs = "IDLE";
			$scope.idlelength = ($filter('filter')($filter('filter')($scope.locations, {
				'status': vm.selectedLogs
			}, true), {
				'login': vm.searchUser
			})).length;
			$scope.alllength = ($filter('filter')($scope.locations, {
				'login': $stateParams.login
			})).length;
			$scope.activelength = ($filter('filter')($filter('filter')($scope.locations, {
				'status': "ACTIVE"
			}, true), {
				'login': vm.searchUser
			})).length;
			$scope.inactivelength = ($filter('filter')($filter('filter')($scope.locations, {
				'status': "INACTIVE"
			}, true), {
				'login': vm.searchUser
			})).length;

			drawLatestlocations($filter('filter')($filter('filter')($scope.locations, {
				'status': vm.selectedLogs
			}, true), {
				'login': vm.searchUser
			}));
			// if (vm.selectedGeofenceId != null) {
			// 	vm.mapAssets = [];
			// 	getGroupLocationAssetsFromES(vm.selectedGeofenceId, null, vm.mapAssets, locations.map);
			// }
		}

		function drawLatestVehicles(vehicles) {
			vehicles.forEach(function (vehicle) {
				var activeInfoWindow;
				var startMarker = new google.maps.Marker({
					map: locations.map,
					position: new google.maps.LatLng(vehicle.latitude, vehicle.longitude),
					title: vehicle.licenseplate,
					icon: {
						scaledSize: new google.maps.Size(
							32, 32),
						url: 'content/images/car.png',
						anchor: new google.maps.Point(
							(32 / 2) + 16, (32 / 2) + 16),
					}
				});
				var infoWindow = new google.maps.InfoWindow();

				var userContent = document.createElement('div'),
					button;
				userContent.innerHTML = '<div>' +
					'<b>Vehicle Id&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : </b>&nbsp;' + vehicle.vehicleid + '<br>' +
					'<b>License Plate&nbsp; : </b>&nbsp;' + vehicle.licenseplate + '<br>' +
					'<b>GPS IMEI&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : </b>&nbsp;' + vehicle.gpsimei + '<br>' +
					'<b>Vehicle Type&nbsp;&nbsp; : </b>&nbsp;' + vehicle.vehicletype + '</div>';

				button = userContent.appendChild(document.createElement('input'));
				button.type = 'button';
				button.value = 'Load travelled path';
				button.className = "button";
				google.maps.event.addDomListener(button, 'click', function () {
					vm.dateRange = null
					vm.dateRange = 'day'
					selectRangePresent()
					vm.type = "vehicle";
					markerUserName = vehicle.licenseplate;
					userPath(vehicle.vehicleid);
				})
				var infowindowPopup = new google.maps.InfoWindow({
					content: userContent,
					pixelOffset: new google.maps.Size(0, -50),
				});
				google.maps.event.addDomListener(startMarker, 'click', function (event) {
					infowindowPopup.setPosition(new google.maps.LatLng(vehicle.latitude, vehicle.longitude));
					infowindowPopup.open(locations.map);

					if (activeInfoWindow != null)
						activeInfoWindow.close();
					activeInfoWindow = infowindowPopup;

				});

				google.maps.event.addDomListener(startMarker, "click", function (event) {
					google.maps.event.trigger(self, "click");

					infowindowPopup.open(locations.map);
				});
				function userPath(id) {
					vm.userGeofences = [];
					vm.assetFilterGeofences = [];
					vm.selectedUserGeofenceId = null;
					vm.prevSelectedUserGeofenceId = null;
					var ele = document.getElementById("userPathModal");
					$(ele).modal('show');
					vm.drawUserPath(id);
					userIdSelected = id;
					var cleratodatefromdate = vm.today();
					// cleratodatefromdate.remove();
				}
			});
		}

		function drawLatestlocations(response) {
			var markers = [{
				Lat: "0.0",
				Lng: "0.0",
				name: "default"
			}];

			if (response) {
				var activeInfoWindow;
				initializeMap(
					response,
					function (latlang) {
						locations.latlng = latlang;
						var bounds = new google.maps.LatLngBounds();

						setupUsersMap(function () {

							$.each(response, function (index, value) {
								var infoWindow = new google.maps.InfoWindow();

								var infoWindow2 = new google.maps.InfoWindow();

								// Create a custom marker

								var imgbg = document.createElement('img');
								imgbg.className = "marker_back_image";

								// if (value.reportingStatus == true) {
								// 	if (value.status == 'ACTIVE') {
								// 		imgbg.src = 'content/images/greenmarker.png';
								// 	} else if (value.status == 'INACTIVE') {
								// 		imgbg.src = 'content/images/redmarker.png';
								// 	} else if (value.status == 'IDLE') {
								// 		imgbg.src = 'content/images/yellowmarker.png';
								// 	} else if (value.status == 'Off duty user') {
								// 		imgbg.src = 'content/images/marker_offduty1.png';
								// 	}
								// } else {
								// 	imgbg.src = 'content/images/marker_active_case.png'
								// }

								if (value.reportingStatus == true) {
									if (value.status == 'ACTIVE') {
										if (value.geoStatus == 'IN') {
											if (value.userRole == 'ROLE_PATROLLER') {
												imgbg.src = 'content/images/pat_active_in.png';
											} else {
												imgbg.src = 'content/images/active_in.png';
											}

										} else {
											if (value.userRole == 'ROLE_PATROLLER') {
												imgbg.src = 'content/images/pat_active_out.png';
											} else {
												imgbg.src = 'content/images/active_out.png';
											}
										}
										// imgbg.src = 'content/images/greenmarker.png';
									} else if (value.status == 'INACTIVE') {
										if (value.geoStatus == 'IN') {
											if (value.userRole == 'ROLE_PATROLLER') {
												imgbg.src = 'content/images/pat_inactive_out.png';
											} else {
												imgbg.src = 'content/images/inactive_in.png';
											}
										} else {
											if (value.userRole == 'ROLE_PATROLLER') {
												imgbg.src = 'content/images/pat_inactive_out.png';
											} else {
												imgbg.src = 'content/images/inactive_out.png';
											}
										}
										// imgbg.src = 'content/images/redmarker.png';
									} else if (value.status == 'IDLE') {
										if (value.geoStatus == 'IN') {
											if (value.userRole == 'ROLE_PATROLLER') {
												imgbg.src = 'content/images/pat_idle_in.png';
											} else {
												imgbg.src = 'content/images/idle_in.png';
											}
										} else {
											if (value.userRole == 'ROLE_PATROLLER') {
												imgbg.src = 'content/images/pat_idle_out.png';
											} else {
												imgbg.src = 'content/images/idle_out.png';
											}
										}
										// imgbg.src = 'content/images/yellowmarker.png';
									} else if (value.status == 'Off duty user') {
										if (value.geoStatus == 'IN') {
											if (value.userRole == 'ROLE_PATROLLER') {
												imgbg.src = 'content/images/pat_off_duty_in.png';
											} else {
												imgbg.src = 'content/images/off_duty_in.png';
											}
										} else {
											if (value.userRole == 'ROLE_PATROLLER') {
												imgbg.src = 'content/images/pat_off_duty_out.png';
											} else {
												imgbg.src = 'content/images/off_duty_out.png';
											}
										}
										// imgbg.src = 'content/images/marker_offduty1.png';
									}
								} else {
									if (value.geoStatus == 'IN') {
										if (value.userRole == 'ROLE_PATROLLER') {
											imgbg.src = 'content/images/pat_caseactive_in.png';
										} else {
											imgbg.src = 'content/images/case_active_in.png';
										}
									} else {
										if (value.userRole == 'ROLE_PATROLLER') {
											imgbg.src = 'content/images/pat_caseactive_out.png';
										} else {
											imgbg.src = 'content/images/case_active_out.png';
										}
									}
									// imgbg.src = 'content/images/marker_active_case.png'
								}

								if (value.address == 'SOS') {
									imgbg.src = 'content/images/alert.png'
								}

								if ($scope.trCases != undefined && $scope.trCases.length > 0) {

									$.each($scope.trCases, function (index, value) {

										var infoWindow = new google.maps.InfoWindow();
										var startMarker = new google.maps.Marker({
											map: locations.map,
											position: new google.maps.LatLng(value.pinLat, value.pinLong),
											title: value.description,

										});
										var infoWindow2 = new google.maps.InfoWindow();
										if (value.priority == 'LOW') {
											startMarker.setIcon('content/images/Pointers-L4.png');
										} else if (value.priority == 'MEDIUM') {

											startMarker.setIcon('content/images/Pointers-M4.png');
										} else if (value.priority == 'HIGH') {
											startMarker.setIcon('content/images/Pointers-H4.png');
										} else if (value.priority == 'CRITICAL') {
											startMarker.setIcon('content/images/Pointer-C4.png');
										}
										var span = '<a userId="' + value.geofenceName + '" class="userpath" >Load travelled path</a>';
										startMarker.content = '<div class="infoWindowContent"><b>Case Id : </b>' + value.id + '<br> <b>Reported By :</b> ' + value.reportedByUser
											+ '<br> <b> Assigned To :</b> ' + value.assignedToUser + '<br> <b>Address :</b> ' + value.address + '</div>';

										$compile(startMarker.content)($scope);

										google.maps.event.addListener(startMarker, 'mouseover', function () {
											infoWindow.setContent('<div class="infoWindowhead">' + startMarker.title + '</div>' + startMarker.content);
											infoWindow.open(locations.map, startMarker);
										});
										// on mouseout
										google.maps.event.addListener(startMarker, 'mouseout', function () {
											infoWindow.close();
										});

										var activeInfoWindow;
										// add content to
										// InfoWindow for
										// click event
										infoWindow2.setContent('<div class="infoWindowhead">' + startMarker.title + '</div>' + startMarker.content);
									})
								}

								function customMarker() {
									this.latlng = new google.maps.LatLng(value.latitude, value.longitude);
									this.setMap(locations.map);
									this.title = value.login;
									this.icon = imgbg;
								}

								bounds.extend(new google.maps.LatLng(value.latitude, value.longitude));

								/*var userContent;// working before browser update
								var travelPathLink = '<a userId=' + value.userid + ' class="userpath" >Load travelled path</a>';
								if (value.userImage != null) {
									userContent = '<div class="infoWindowhead">' + value.login
											+ '</div><div class="infoWindowContent"><div class="rows"><div class="col-md-4"><img src="data:image/png;base64,' + value.userImage
											+ '" style="width: 50px; height: 60px;"></div><div class="col-md-8"><p>' + value.address + '</p><p>Phone : ' + value.phone
											+ '</p><p>Battery :' + value.batteryPercentage + ' %</p><p>' + travelPathLink + '</p></div></div></div>';
								} else {
									userContent = '<div class="infoWindowhead">'
											+ value.login
											+ '</div><div class="infoWindowContent"><div class="rows"><div class="col-md-4"><img src="content/images/userimg.gif" style="width: 50px; height: 60px;"></div><div class="col-md-8"><p>'
											+ value.address + '</p><p>Phone : ' + value.phone + '</p><p>Battery :' + value.batteryPercentage + ' %</p><p>' + travelPathLink
											+ '</p></div></div></div>';
								}*/

								var userContent = document.createElement('div'),
									button;


								if (value.userImage != null) {
									userContent.innerHTML = '<div class="infoWindowhead">' + value.login
										+ '</div><div class="infoWindowContent"><div class="rows"><div class="col-md-8"><p>' + value.address + '</p><p><b>Phone</b> : ' + value.phone;
									button = userContent.appendChild(document.createElement('input'));
									button.type = 'button';
									button.value = 'Load travelled path';
									button.className = "button";

									// userContent.innerHTML = '<div class="infoWindowhead">' + value.login
									// + '</div><div class="infoWindowContent"><div class="rows"><div class="col-md-4"><img src="data:image/png;base64,' + value.userImage
									// + '" style="width: 50px; height: 60px;"></div><div class="col-md-8"><p>' + value.address + '</p><p><b>Phone</b> : ' + value.phone;
									// button = userContent.appendChild(document.createElement('input'));
									// button.type = 'button';
									// button.value = 'Load travelled path';
									// button.className = "button";

									google.maps.event.addDomListener(button, 'click', function () {
										markerUserName = value.login;
										userPath(value.userid);
									})
								} else {
									/*userContent.innerHTML = '<div class="infoWindowhead">' + value.login
									+ '</div><div class="infoWindowContent"><div class="rows"><div class="col-md-8"><p>' + value.address + '</p><p>Phone : ' + value.phone
									+ '</p><p>Battery :' + value.batteryPercentage+' %</p></div></div><div class="col-md-4"> <img src="content/images/userimg.gif" '
									+ '" style="width: 100px; height: 120px;"></div></div>'  ;*/

									// userContent.innerHTML = '<div class="infoWindowhead"><span style="margin-left:10px;"><b>' + value.login
									// 	+ '</b></span></div><div style="width: 300px; height: 120px;" class="infoWindowContent"><div class="rows"><div class="col-md-7"><p>' + value.address + '</p><p><b>Phone</b> : ' + value.phone
									// 	+ '</p><b>Latitude</b> : ' + value.latitude + '</p> <p> <b>Longitude1</b> : ' + value.longitude + '</p> </div></div></div>';

									button = userContent.appendChild(document.createElement('input'));
									button.type = 'button';
									button.value = 'Load travelled path';
									button.className = "button"; // set the CSS class

									var text1 = value.login
									var text2 = value.latitude.toFixed(5) + " , " + value.longitude.toFixed(5);
									var text3 = value.address

									var boldText1 = document.createElement("b");
									var dataTag1 = document.createElement("h4");

									var dataTag2 = document.createElement("h4");
									var dataTag3 = document.createElement("h5");

									var textContent1 = document.createTextNode(text1);
									var textContent2 = document.createTextNode(text2);
									var textContent3 = document.createTextNode(text3);

									boldText1.appendChild(textContent1);
									dataTag1.appendChild(boldText1);

									dataTag2.appendChild(textContent2);
									dataTag3.appendChild(textContent3);

									userContent.appendChild(dataTag1);
									userContent.appendChild(dataTag2);

									if (value.address) {
										userContent.appendChild(dataTag3);
									}

									// userContent.innerHTML = '<div class="infoWindowhead"><span style="margin-left:10px;"><b>' + value.login
									// 	+ '</b></span></div><div style="width: 300px; height: 120px;" class="infoWindowContent"><div class="rows"><div class="col-md-7"><p>' + value.address + '</p><p><b>Phone</b> : ' + value.phone
									// 	+ '</p><b>Latitude</b> : ' + value.latitude + '</p> <p> <b>Longitude</b> : ' + value.longitude + '</p> </div></div><div class="col-md-5"><br><br> <img src="content/images/userimg.gif" style="width: 80px; height: 90px; margin-top:26px;"></div></div>';

									// button = userContent.appendChild(document.createElement('input'));
									// button.type = 'button';
									// button.value = 'Load travelled path';
									// button.className = "button"; // set the CSS class

									google.maps.event.addDomListener(button, 'click', function () {
										//   console.log('2222')
										vm.dateRange = null
										vm.dateRange = 'day'
										selectRangePresent()
										vm.type = "user";
										markerUserName = value.login;
										userPath(value.userid);
									});

									userPathId = value.userid;
									// console.log('userPathId',userPathId);
								}

								function userPath(id) {
									vm.userGeofences = [];
									vm.assetFilterGeofences = [];
									vm.selectedUserGeofenceId = null;
									vm.prevSelectedUserGeofenceId = null;
									var ele = document.getElementById("userPathModal");
									$(ele).modal('show');
									vm.drawUserPath(id);
									userIdSelected = id;
									var cleratodatefromdate = vm.today();
									// cleratodatefromdate.remove();
								}
								var infowindowPopup = new google.maps.InfoWindow({
									content: userContent,
									pixelOffset: new google.maps.Size(0, -50),
								});

								customMarker.prototype = new google.maps.OverlayView();

								customMarker.prototype.draw = function () {
									var self = this;
									var div = this.div;

									if (!div) {

										div = this.div = document.createElement('div');
										div.id = 'marker';
										div.style.width = '100px';
										div.style.height = '100px';

										var div_pointer = document.createElement('div');
										div_pointer.className = 'triangle';

										var image_container = document.createElement('div');
										image_container.className = 'image_container';

										var img = document.createElement('img');
										img.className = "marker_image";
										var image2 = "data:image/png;base64,";
										image2 += value.userImage;
										if (value.userImage == undefined) {
											image2 = "content/images/userimg.gif";
										}

										img.src = image2;

										var name_container = document.createElement('div');
										name_container.className = 'name_container';

										var exit = document.createElement('div');
										exit.className = 'exit';
										exit.innerHTML = '<img className="exit_image" style="width:30px; height:30px;" src="https://cdn0.iconfinder.com/data/icons/large-glossy-icons/512/Delete.png">'
											+ '</img>';
										exit.style.display = 'none';

										div.appendChild(image_container);
										image_container.appendChild(img);
										image_container.appendChild(imgbg);

										div.appendChild(exit);

										name_container.onmouseover = function () {
											name_container.style.opacity = '0.6';
											div.style.zIndex = '1000'
										};
										name_container.onmouseout = function () {
											name_container.style.opacity = '0';
											div.style.zIndex = '100'
										};

										// add content to
										// InfoWindow for
										// click event
										infoWindow2.setContent('<div class="infoWindowhead">' + value.login + '</div>' + userContent);
										if (activeInfoWindow != null)
											activeInfoWindow.close();

										google.maps.event.addDomListener(imgbg, 'click', function (event) {
											infowindowPopup.setPosition(new google.maps.LatLng(value.latitude, value.longitude));
											infowindowPopup.open(locations.map);

											if (activeInfoWindow != null)
												activeInfoWindow.close();
											/* 	$(".userpath").click(function() { // working before browser update

													var ele = document.getElementById("userPathModal");
													$(ele).modal('show');
													vm.drawUserPath($(this).attr('userid'));
													var cleratodatefromdate = vm.today();
													// cleratodatefromdate.remove();
												});*/
											activeInfoWindow = infowindowPopup;

										});

										google.maps.event.addDomListener(imgbg, "click", function (event) {
											google.maps.event.trigger(self, "click");

											infowindowPopup.open(locations.map);
										});

										var panes = this.getPanes();

										panes.overlayImage.appendChild(div);

									}

									var point = this.getProjection().fromLatLngToDivPixel(this.latlng);

									if (point) {
										div.style.left = (point.x - 50) + 'px';
										div.style.top = (point.y - 125) + 'px';
									}
								}

								customMarker.prototype.remove = function () {

									if (this.div) {
										this.div.parentNode.removeChild(this.div);
										this.div = null;
									}

								}

								customMarker.prototype.getPosition = function () {
									return this.latlng;
								}
								markers.forEach(function (marker) {
									// image =
									// value.userImage;

									var overlay = new customMarker();
								});
								// end

							});

							locations.map.fitBounds(bounds);
						});
					});
			}
			drawLatestVehicles(vm.vehicles);

		}

		function setupUsersMap(callback) {
			/* var myOptions = {
				zoom: 13,
				center: locations.latlng,
				mapTypeId: google.maps.MapTypeId.ROADMAP
			}; */
			locations.map = new google.maps.Map(document.getElementById("map_canvas"), myOption(13, locations.latlng));
			geoLocation(locations.map)
			locations.overlay = new google.maps.OverlayView();
			locations.overlay.draw = function () {
			};
			locations.overlay.setMap($scope.map);
			locations.element = document.getElementById('map_canvas');
			callback();

			isLevel1AssetsVisible = false;
			isLevel2AssetsVisible = false;
			isLevel3AssetsVisible = false;
			isLevel4AssetsVisible = false;
			isLevel5AssetsVisible = false;
			if (vm.assetTypeValues != null) {
				if (vm.assetTypeValues.length > 0) {
					for (var index = 0; index < vm.assetTypeValues.length; index++) {
						var assetType = vm.assetTypeValues[index];
						var typeName = assetType.assetTypeName;
						var typeAssetsList = [];
						if (assetType.assets != null) {
							typeAssetsList = assetType.assets;
						}

						vm.mapAssets.forEach(function (asset) {
							if (typeName == asset.assetTypeName) {
								typeAssetsList.push(asset);
							}
						});
						assetType.assets = typeAssetsList;
						vm.assetTypeValues[index] = assetType;
					}
					var assetList = [];
					for (var index = 0; index < vm.assetTypeValues.length; index++) {
						if (vm.assetTypeValues[index].zoomLevel > 11 && vm.assetTypeValues[index].zoomLevel < 15) {
							vm.assetTypeValues[index].assets.forEach(function (assetVal) {
								assetList.push(assetVal);
							});
						}
					}
					drawAssets(assetList, "level1");
					isLevel1AssetsVisible = true;
				}
			}
			mapZoomChange();
		}

		function mapZoomChange() {
			google.maps.event.addListener(locations.map, 'zoom_changed', function () {
				vm.zoomLevel = locations.map.getZoom();
				document.getElementById("myspan").textContent = "ZOOM : " + vm.zoomLevel;
				if (vm.zoomLevel < 15) {
					for (var i = 0; i < level2AssetsMarkers.length; i++) {
						level2AssetsMarkers[i].setMap(null);
					}

					for (i = 0; i < level2AssetsPolyline.length; i++) {
						level2AssetsPolyline[i].setMap(null);
					}
					isLevel2AssetsVisible = false;
				}
				if (vm.zoomLevel == 15) {
					if (!isLevel2AssetsVisible) {
						var chamberList = [];
						for (var index = 0; index < vm.assetTypeValues.length; index++) {
							if (vm.assetTypeValues[index].zoomLevel == 15) {
								vm.assetTypeValues[index].assets.forEach(function (assetVal) {
									chamberList.push(assetVal);
								});
							}
						}
						drawAssets(chamberList, "level2");
						isLevel2AssetsVisible = true;
					}
				}

				if (vm.prevZoomLevel == 16 && vm.zoomLevel == 15) {
					for (var i = 0; i < level3AssetsMarkers.length; i++) {
						level3AssetsMarkers[i].setMap(null);
					}

					for (i = 0; i < level3AssetsPolyline.length; i++) {
						level3AssetsPolyline[i].setMap(null);
					}
					isLevel3AssetsVisible = false;
				}

				if ((vm.prevZoomLevel == 15 && vm.zoomLevel == 16)) {
					if (!isLevel3AssetsVisible) {
						var couplerList = [];
						for (var index = 0; index < vm.assetTypeValues.length; index++) {
							if (vm.assetTypeValues[index].zoomLevel == 16) {
								vm.assetTypeValues[index].assets.forEach(function (assetVal) {
									couplerList.push(assetVal);
								});
							}
						}
						drawAssets(couplerList, "level3");
						isLevel3AssetsVisible = true;
					}
				}

				if (vm.prevZoomLevel == 17 && vm.zoomLevel == 16) {
					for (var i = 0; i < level4AssetsMarkers.length; i++) {
						level4AssetsMarkers[i].setMap(null);
					}

					for (i = 0; i < level4AssetsPolyline.length; i++) {
						level4AssetsPolyline[i].setMap(null);
					}
					isLevel4AssetsVisible = false;
				}

				if ((vm.prevZoomLevel == 16 && vm.zoomLevel == 17)) {
					if (!isLevel4AssetsVisible) {
						var poleList = [];
						for (var index = 0; index < vm.assetTypeValues.length; index++) {
							if (vm.assetTypeValues[index].zoomLevel == 17) {
								vm.assetTypeValues[index].assets.forEach(function (assetVal) {
									poleList.push(assetVal);
								});
							}
						}
						drawAssets(poleList, "level4");
						isLevel4AssetsVisible = true;
					}
				}

				if (vm.prevZoomLevel == 18 && vm.zoomLevel == 17) {
					for (var i = 0; i < level5AssetsMarkers.length; i++) {
						level5AssetsMarkers[i].setMap(null);
					}

					for (i = 0; i < level5AssetsPolyline.length; i++) {
						level5AssetsPolyline[i].setMap(null);
					}
					isLevel5AssetsVisible = false;
				}
				if ((vm.prevZoomLevel == 17 && vm.zoomLevel == 18)) {
					if (!isLevel5AssetsVisible) {
						var otherList = [];
						for (var index = 0; index < vm.assetTypeValues.length; index++) {
							if (vm.assetTypeValues[index].zoomLevel == 18) {
								vm.assetTypeValues[index].assets.forEach(function (assetVal) {
									otherList.push(assetVal);
								});
							}
						}
						drawAssets(otherList, "level5");
						isLevel5AssetsVisible = true;
					}
				}

				vm.prevZoomLevel = vm.zoomLevel;
			});
		}

		function mapZoomChangeForPath() {
			google.maps.event.addListener(userPaths.map, 'zoom_changed', function () {
				vm.zoomLevel = userPaths.map.getZoom();
				document.getElementById("userpathzoom").textContent = "ZOOM : " + vm.zoomLevel;
				if (vm.zoomLevel < 15) {
					for (var i = 0; i < level2AssetsMarkersPath.length; i++) {
						level2AssetsMarkersPath[i].setMap(null);
					}

					for (i = 0; i < level2AssetsPolylinePath.length; i++) {
						level2AssetsPolylinePath[i].setMap(null);
					}
					isLevel2AssetsVisiblePath = false;
				}
				if (vm.zoomLevel == 15) {
					if (!isLevel2AssetsVisiblePath) {
						var chamberList = [];
						for (var index = 0; index < vm.assetTypeValuesPath.length; index++) {
							if (vm.assetTypeValuesPath[index].zoomLevel == 15) {
								vm.assetTypeValuesPath[index].assets.forEach(function (assetVal) {
									chamberList.push(assetVal);
								});
							}
						}
						drawAssetsForPath(chamberList, "level2");
						isLevel2AssetsVisiblePath = true;
					}
				}

				if (vm.prevZoomLevel == 16 && vm.zoomLevel == 15) {
					for (var i = 0; i < level3AssetsMarkersPath.length; i++) {
						level3AssetsMarkersPath[i].setMap(null);
					}

					for (i = 0; i < level3AssetsPolylinePath.length; i++) {
						level3AssetsPolylinePath[i].setMap(null);
					}
					isLevel3AssetsVisiblePath = false;
				}

				if ((vm.prevZoomLevel == 15 && vm.zoomLevel == 16)) {
					if (!isLevel3AssetsVisiblePath) {
						var couplerList = [];
						for (var index = 0; index < vm.assetTypeValuesPath.length; index++) {
							if (vm.assetTypeValuesPath[index].zoomLevel == 16) {
								vm.assetTypeValuesPath[index].assets.forEach(function (assetVal) {
									couplerList.push(assetVal);
								});
							}
						}
						drawAssetsForPath(couplerList, "level3");
						isLevel3AssetsVisiblePath = true;
					}
				}

				if (vm.prevZoomLevel == 17 && vm.zoomLevel == 16) {
					for (var i = 0; i < level4AssetsMarkersPath.length; i++) {
						level4AssetsMarkersPath[i].setMap(null);
					}

					for (i = 0; i < level4AssetsPolylinePath.length; i++) {
						level4AssetsPolylinePath[i].setMap(null);
					}
					isLevel4AssetsVisiblePath = false;
				}

				if ((vm.prevZoomLevel == 16 && vm.zoomLevel == 17)) {
					if (!isLevel4AssetsVisiblePath) {
						var poleList = [];
						for (var index = 0; index < vm.assetTypeValuesPath.length; index++) {
							if (vm.assetTypeValuesPath[index].zoomLevel == 17) {
								vm.assetTypeValuesPath[index].assets.forEach(function (assetVal) {
									poleList.push(assetVal);
								});
							}
						}
						drawAssetsForPath(poleList, "level4");
						isLevel4AssetsVisiblePath = true;
					}
				}

				if (vm.prevZoomLevel == 18 && vm.zoomLevel == 17) {
					for (var i = 0; i < level5AssetsMarkersPath.length; i++) {
						level5AssetsMarkersPath[i].setMap(null);
					}

					for (i = 0; i < level5AssetsPolylinePath.length; i++) {
						level5AssetsPolylinePath[i].setMap(null);
					}
					isLevel5AssetsVisiblePath = false;
				}
				if ((vm.prevZoomLevel == 17 && vm.zoomLevel == 18)) {
					if (!isLevel5AssetsVisiblePath) {
						var otherList = [];
						for (var index = 0; index < vm.assetTypeValuesPath.length; index++) {
							if (vm.assetTypeValuesPath[index].zoomLevel == 18) {
								vm.assetTypeValuesPath[index].assets.forEach(function (assetVal) {
									otherList.push(assetVal);
								});
							}
						}
						drawAssetsForPath(otherList, "level5");
						isLevel5AssetsVisiblePath = true;
					}
				}

				vm.prevZoomLevel = vm.zoomLevel;
			});
		}

		function drawAssets(response, level) {
			if (response) {
				$.each(response, function (index, value) {
					if (value.assetTypeLayout == null) {
						value.assetTypeName = value.assetType.name;
						value.assetTypeLayout = value.assetType.layout;
						value.assetTypeImagePath = value.assetType.imagePath;
					}
					if (value.assetTypeLayout != null && value.assetTypeLayout == 'FIXED') {
						if (value.assetCoordinates.length > 1) {
							// drawPolyline(value);
							var value1 = value;
							value1.assetCoordinates = [value.assetCoordinates[value.assetCoordinates.length - 1]];

							drawFixedAsset(value1, false, level);

						} else {
							drawFixedAsset(value, false, level);
						}
					}
					if (value.assetTypeLayout != null && value.assetTypeLayout == 'SPREAD') {
						drawPolyline(value, locations.map, level);
					}

				});
			}
			switch (level) {
				case "level1":
					isLevel1AssetsVisible = true;
					break;
				case "level2":
					isLevel2AssetsVisible = true;
					break;
				case "level3":
					isLevel3AssetsVisible = true;
					break;
				case "level4":
					isLevel4AssetsVisible = true;
					break;
				case "level5":
					isLevel5AssetsVisible = true;
					break;
				default:
					break;
			}
		}

		function drawAssetsForPath(response, level) {
			if (response) {
				$.each(response, function (index, value) {
					if (value.assetTypeLayout == null) {
						value.assetTypeName = value.assetType.name;
						value.assetTypeLayout = value.assetType.layout;
						value.assetTypeImagePath = value.assetType.imagePath;
					}
					if (value.assetTypeLayout != null && value.assetTypeLayout == 'FIXED') {
						if (value.assetCoordinates.length > 1) {
							var value1 = value;
							value1.assetCoordinates = [value.assetCoordinates[value.assetCoordinates.length - 1]];

							drawFixedAssetForPath(value1, false, level);

						} else {
							drawFixedAssetForPath(value, false, level);
						}
					}
					if (value.assetTypeLayout != null && value.assetTypeLayout == 'SPREAD') {
						drawPolylineForPath(value, userPaths.map, level);
					}

				});
			}
			switch (level) {
				case "level1":
					isLevel1AssetsVisiblePath = true;
					break;
				case "level2":
					isLevel2AssetsVisiblePath = true;
					break;
				case "level3":
					isLevel3AssetsVisiblePath = true;
					break;
				case "level4":
					isLevel4AssetsVisiblePath = true;
					break;
				case "level5":
					isLevel5AssetsVisiblePath = true;
					break;
				default:
					break;
			}
		}

		function drawFixedAsset(fixed, hasParent, level) {

			$.each(fixed.assetCoordinates, function (index, value1) {
				if (openedInfoWindow != null) {
					openedInfoWindow.close();
				}

				var infoWindow = new google.maps.InfoWindow();


				var iconUrl = "";
				var isFixedAsset = false;
				iconUrl = fixed.assetTypeImagePath;
				isFixedAsset = true;

				var iconSize = 8;
				if (vm.zoomLevel == 10) {
					iconSize = 8;
				} else if (vm.zoomLevel == 11) {
					iconSize = 16;
				}

				if (isFixedAsset) {
					iconSize = 24;
				}

				var startMarker = new google.maps.Marker({
					map: locations.map,
					position: new google.maps.LatLng(
						value1.latitude,
						value1.longitude),
					title: fixed.name,
					icon: {
						scaledSize: new google.maps.Size(
							iconSize, iconSize),
						url: iconUrl,
						anchor: new google.maps.Point(
							iconSize / 2, iconSize / 2),
					},
				});

				// if (vm.zoomLevel == 10) {
				//     startMarker.setLabel('');
				// } else if (vm.zoomLevel == 11) {
				//     var label = { color: '#000000', fontWeight: 'bold', fontSize: '12px', text: fixed.name };
				//     startMarker.setLabel(label);
				// }

				// if (fixed.status != null &&
				//     fixed.status === false) {
				//     startMarker.setLabel({
				//         color: 'red',
				//         fontSize: '30px',
				//         fontWeight: '20px',
				//         text: '\u2297'
				//     });
				// }
				// Add marker to the array.

				// markers.push(startMarker);

				switch (level) {
					case "level1":
						level1AssetsMarkers.push(startMarker);
						break;
					case "level2":
						level2AssetsMarkers.push(startMarker);
						break;
					case "level3":
						level3AssetsMarkers.push(startMarker);
						break;
					case "level4":
						level4AssetsMarkers.push(startMarker);
						break;
					case "level5":
						level5AssetsMarkers.push(startMarker);
						break;
					default:
						markers.push(startMarker);
						break;
				}
				var infoWindow2 = new google.maps.InfoWindow();
				var infoStr = "";
				var otdr = "";

				var userContent = document.createElement('div'),
					button;

				userContent.innerHTML = '<div class="infoWindowhead">' + startMarker.title +
					'</div><div class="infoWindowContent">' +
					'<b>Lat</b> :' +
					fixed.assetCoordinates[fixed.assetCoordinates.length - 1].latitude +
					'<br><b>Long</b> :' +
					fixed.assetCoordinates[fixed.assetCoordinates.length - 1].longitude +
					'<br>' + infoStr + '</div>';

				google.maps.event.addListener(startMarker,
					'dblclick',
					function () {
						infoWindow2.close();
					});

				var activeInfoWindow;
				var infoWindow2 = new google.maps.InfoWindow({
					content: userContent
				});
				// add listener on InfoWindow for click
				// event
				google.maps.event.addListener(startMarker, 'click', function () {
					AssetByID.get(fixed.id, function (response) {
						var infoWindow2 = new google.maps.InfoWindow();
						var infoStr = "";
						var otdr = "";
						var assetTypeAttributeValuesList = response.assetTypeAttributeValues;
						assetTypeAttributeValuesList.sort(function (a, b) {
							var nameA = a.assetTypeAttribute.name.toLowerCase(),
								nameB = a.assetTypeAttribute.name.toLowerCase()
							if (nameA < nameB) // sort string ascending
								return -1
							if (nameA > nameB)
								return 1
							return 0 // default return value (no sorting)
						});
						for (var i = 0; i < assetTypeAttributeValuesList.length; i++) {
							var home = assetTypeAttributeValuesList[i];
							infoStr += '<br><b>' +
								home.assetTypeAttribute.name +
								'</b> : ' +
								home.attributeValue;
							otdr = home;
						}

						var userContent = document.createElement('div'),
							button;
						userContent.innerHTML = '<div class="infoWindowhead">' + "<b>Name</b> : " + startMarker.title +
							'</div><div class="infoWindowContent">' +
							"<b>Asset Type</b> : " + fixed.assetTypeName +
							"<br><b>Latitude</b> :" +
							fixed.assetCoordinates[fixed.assetCoordinates.length - 1].latitude +
							'<br><b>Longitude</b> :' +
							fixed.assetCoordinates[fixed.assetCoordinates.length - 1].longitude +
							'<br>' + infoStr + '</div>';

						button = userContent.appendChild(document.createElement('input'));
						button.type = 'button';
						button.value = 'Creat Case';
						button.className = "button";

						google.maps.event.addDomListener(button, 'click', function () {
							$state.go("tr-case.new", {
								'lat': fixed.assetCoordinates[fixed.assetCoordinates.length - 1].latitude,
								'lng': fixed.assetCoordinates[fixed.assetCoordinates.length - 1].longitude,
								'desc': fixed.name
							});
						});


						var infoWindow2 = new google.maps.InfoWindow({
							content: userContent
						});
						if (prevInfoWindow != null)
							prevInfoWindow.close();
						infoWindow2.open(locations.map, startMarker);
						setTimeout(function () { infoWindow2.close(); }, 5000);

						activeInfoWindow = infoWindow2;
						prevInfoWindow = infoWindow2;
					});

				});
				openedInfoWindow = infoWindow2;
			});
		}

		function drawPolyline(spread, map, level) {

			var spreadAssetCoordinates = [];
			var spreadColor = 0;
			var spreadWidth = 0;
			var spreadOpacity = 0;

			vm.assetTypes.forEach(function (assetType) {
				if (assetType.name == spread.assetTypeName) {
					spreadColor = assetType.colorcode;
					spreadWidth = assetType.lineWidth * 0.05;
					spreadOpacity = assetType.transparency * 0.01;
				}
			});

			$.each(spread.assetCoordinates, function (index, value) {

				spreadAssetCoordinates.push({
					lat: value.latitude,
					lng: value.longitude
				});
			});
			if (spreadAssetCoordinates.length > 1) {
				var midLatLng = {
					lat: spread.assetCoordinates[spread.assetCoordinates.length - 1].latitude,
					lng: spread.assetCoordinates[spread.assetCoordinates.length - 1].longitude
				};

				var polylineColor = '';
				var distanceFromPOP = '';
				var fiberCoreVal = '';
				var infowindow = null;
				if (spread.assetTypeLayout == "SPREAD") {
					polylineColor = spreadColor;
				}
				var polylineWeight = spreadWidth;
				var strokeOpacityVal = spreadOpacity;

				var polylinepath = new google.maps.Polyline({
					path: spreadAssetCoordinates,
					geodesic: true,
					strokeColor: polylineColor,
					strokeOpacity: strokeOpacityVal,
					strokeWeight: polylineWeight,
					fillColor: polylineColor,
					fillOpacity: 0.3,
					editable: false,
				});

				google.maps.event.addListener(polylinepath, 'click', function (event) {
					if (openedInfoWindow != null) {
						openedInfoWindow.close();
					}
					var disVal = 0;
					var cLocations = [];

					if (spread.description == null || spread.description == undefined) {
						spread.description = "";
					}

					if (spread.assetTypeLayout == "SPREAD") {
						AssetByID.get(spread.id, function (assetVal) {
							spread = assetVal;
							var infoStr = "";
							var assetTypeAttributeValuesList = spread.assetTypeAttributeValues;
							assetTypeAttributeValuesList.sort(function (a, b) {
								var nameA = a.assetTypeAttribute.name.toLowerCase(),
									nameB = b.assetTypeAttribute.name.toLowerCase()
								if (nameA < nameB) // sort string ascending
									return -1
								if (nameA > nameB)
									return 1
								return 0 // default return value (no sorting)
							});
							for (var i = 0; i < assetTypeAttributeValuesList.length; i++) {
								var home = assetTypeAttributeValuesList[i];

								var attrVal = "";
								if (home.attributeValue != null) {
									attrVal = home.attributeValue;
								}
								infoStr += '<br><b>' +
									home.assetTypeAttribute.name +
									'</b> : ' +
									attrVal;
							}
							var spreadCoordinates = spread.assetCoordinates;
							if (spreadCoordinates.length > 0) {
								for (var index in spreadCoordinates) {
									if (index > 0) {
										disVal += distanceCal(spreadCoordinates[index].latitude, spreadCoordinates[index].longitude, spreadCoordinates[index - 1].latitude, spreadCoordinates[index - 1].longitude);
									}
								}
							}

							var userContent = document.createElement('div');

							userContent.innerHTML = '<div class="infoWindowhead">' +
								'<b>Name : </b> ' + spread.name + "<br><b>Asset Type : </b> " + spread.assetType.name + '</br>' +
								'<b>Distance : </b> ' + (parseFloat(disVal)).toFixed(2) + ' Km</br>';

							infowindow = new google.maps.InfoWindow({
								content: userContent
							});
						});
					}
					if (infowindow != null) {
						infowindow.setPosition(event.latLng);
						infowindow.open(locations.map);
						openedInfoWindow = infowindow;
					}
				});

				var polylinepathDuct = new google.maps.Polyline({
					path: spreadAssetCoordinates,
					geodesic: true,
					strokeColor: polylineColor,
					strokeOpacity: 1,
					strokeWeight: 2,
					fillColor: polylineColor,
					fillOpacity: 0.3,
					editable: false
				});

				polylinepath.setMap(locations.map);

				// polylines.push(polylinepath);
				switch (level) {
					case "level1":
						level1AssetsPolyline.push(polylinepath);
						break;
					case "level2":
						level2AssetsPolyline.push(polylinepath);
						break;
					case "level3":
						level3AssetsPolyline.push(polylinepath);
						break;
					case "level4":
						level4AssetsPolyline.push(polylinepath);
						break;
					case "level5":
						level5AssetsPolyline.push(polylinepath);
						break;
					default:
						polylines.push(polylinepath);
						break;
				}
			}
		}

		function drawFixedAssetForPath(fixed, hasParent, level) {

			$.each(fixed.assetCoordinates, function (index, value1) {
				if (openedInfoWindow != null) {
					openedInfoWindow.close();
				}

				var infoWindow = new google.maps.InfoWindow();


				var iconUrl = "";
				var isFixedAsset = false;
				iconUrl = fixed.assetTypeImagePath;
				isFixedAsset = true;

				var iconSize = 16;
				if (isFixedAsset) {
					iconSize = 24;
				}

				var startMarker = new google.maps.Marker({
					map: userPaths.map,
					position: new google.maps.LatLng(
						value1.latitude,
						value1.longitude),
					title: fixed.name,
					icon: {
						scaledSize: new google.maps.Size(
							iconSize, iconSize),
						url: iconUrl,
						anchor: new google.maps.Point(
							iconSize / 2, iconSize / 2),
					},
				});

				switch (level) {
					case "level1":
						level1AssetsMarkersPath.push(startMarker);
						break;
					case "level2":
						level2AssetsMarkersPath.push(startMarker);
						break;
					case "level3":
						level3AssetsMarkersPath.push(startMarker);
						break;
					case "level4":
						level4AssetsMarkersPath.push(startMarker);
						break;
					case "level5":
						level5AssetsMarkersPath.push(startMarker);
						break;
					default:
						markersForPath.push(startMarker);
						break;
				}
				var infoWindow2 = new google.maps.InfoWindow();
				var infoStr = "";
				var otdr = "";

				var userContent = document.createElement('div'),
					button;

				userContent.innerHTML = '<div class="infoWindowhead">' + startMarker.title +
					'</div><div class="infoWindowContent">' +
					'<b>Lat</b> :' +
					fixed.assetCoordinates[fixed.assetCoordinates.length - 1].latitude +
					'<br><b>Long</b> :' +
					fixed.assetCoordinates[fixed.assetCoordinates.length - 1].longitude +
					'<br>' + infoStr + '</div>';

				google.maps.event.addListener(startMarker,
					'dblclick',
					function () {
						infoWindow2.close();
					});

				var activeInfoWindow;
				var infoWindow2 = new google.maps.InfoWindow({
					content: userContent
				});
				// add listener on InfoWindow for click
				// event
				google.maps.event.addListener(startMarker, 'click', function () {
					AssetByID.get(fixed.id, function (response) {
						var infoWindow2 = new google.maps.InfoWindow();
						var infoStr = "";
						var otdr = "";
						var assetTypeAttributeValuesList = response.assetTypeAttributeValues;
						assetTypeAttributeValuesList.sort(function (a, b) {
							var nameA = a.assetTypeAttribute.name.toLowerCase(),
								nameB = a.assetTypeAttribute.name.toLowerCase()
							if (nameA < nameB) // sort string ascending
								return -1
							if (nameA > nameB)
								return 1
							return 0 // default return value (no sorting)
						});
						for (var i = 0; i < assetTypeAttributeValuesList.length; i++) {
							var home = assetTypeAttributeValuesList[i];
							infoStr += '<br><b>' +
								home.assetTypeAttribute.name +
								'</b> : ' +
								home.attributeValue;
							otdr = home;
						}

						var userContent = document.createElement('div'),
							button;
						userContent.innerHTML = '<div class="infoWindowhead">' + "<b>Name</b> : " + startMarker.title +
							'</div><div class="infoWindowContent">' +
							"<b>Asset Type</b> : " + fixed.assetTypeName +
							"<br><b>Latitude</b> :" +
							fixed.assetCoordinates[fixed.assetCoordinates.length - 1].latitude +
							'<br><b>Longitude</b> :' +
							fixed.assetCoordinates[fixed.assetCoordinates.length - 1].longitude +
							'<br>' + infoStr + '</div>';

						button = userContent.appendChild(document.createElement('input'));
						button.type = 'button';
						button.value = 'Creat Case';
						button.className = "button";

						google.maps.event.addDomListener(button, 'click', function () {
							$state.go("tr-case.new", {
								'lat': fixed.assetCoordinates[fixed.assetCoordinates.length - 1].latitude,
								'lng': fixed.assetCoordinates[fixed.assetCoordinates.length - 1].longitude,
								'desc': fixed.name
							});
						});


						var infoWindow2 = new google.maps.InfoWindow({
							content: userContent
						});
						if (prevInfoWindow != null)
							prevInfoWindow.close();
						infoWindow2.open(userPaths.map, startMarker);
						setTimeout(function () { infoWindow2.close(); }, 5000);

						activeInfoWindow = infoWindow2;
						prevInfoWindow = infoWindow2;
					});

				});
				openedInfoWindow = infoWindow2;
			});
		}

		function drawPolylineForPath(spread, map, level) {

			var spreadAssetCoordinates = [];
			var spreadColor = 0;
			var spreadWidth = 0;
			var spreadOpacity = 0;

			vm.assetTypes.forEach(function (assetType) {
				if (assetType.name == spread.assetTypeName) {
					spreadColor = assetType.colorcode;
					spreadWidth = assetType.lineWidth * 0.05;
					spreadOpacity = assetType.transparency * 0.01;
				}
			});
			$.each(spread.assetCoordinates, function (index, value) {

				spreadAssetCoordinates.push({
					lat: value.latitude,
					lng: value.longitude
				});
			});
			if (spreadAssetCoordinates.length > 1) {
				var midLatLng = {
					lat: spread.assetCoordinates[spread.assetCoordinates.length - 1].latitude,
					lng: spread.assetCoordinates[spread.assetCoordinates.length - 1].longitude
				};

				var polylineColor = '';
				var distanceFromPOP = '';
				var fiberCoreVal = '';
				var infowindow = null;
				if (spread.assetTypeLayout == "SPREAD") {
					polylineColor = spreadColor;
				}
				var polylineWeight = spreadWidth;
				var strokeOpacityVal = spreadOpacity;

				var polylinepath = new google.maps.Polyline({
					path: spreadAssetCoordinates,
					geodesic: true,
					strokeColor: polylineColor,
					strokeOpacity: strokeOpacityVal,
					strokeWeight: polylineWeight,
					fillColor: polylineColor,
					fillOpacity: 0.3,
					editable: false
				});

				google.maps.event.addListener(polylinepath, 'click', function (event) {
					if (openedInfoWindow != null) {
						openedInfoWindow.close();
					}
					var disVal = 0;
					var cLocations = [];

					if (spread.description == null || spread.description == undefined) {
						spread.description = "";
					}

					if (spread.assetTypeLayout == "SPREAD") {
						AssetByID.get(spread.id, function (assetVal) {
							spread = assetVal;
							var infoStr = "";
							var assetTypeAttributeValuesList = spread.assetTypeAttributeValues;
							assetTypeAttributeValuesList.sort(function (a, b) {
								var nameA = a.assetTypeAttribute.name.toLowerCase(),
									nameB = b.assetTypeAttribute.name.toLowerCase()
								if (nameA < nameB) // sort string ascending
									return -1
								if (nameA > nameB)
									return 1
								return 0 // default return value (no sorting)
							});
							for (var i = 0; i < assetTypeAttributeValuesList.length; i++) {
								var home = assetTypeAttributeValuesList[i];

								var attrVal = "";
								if (home.attributeValue != null) {
									attrVal = home.attributeValue;
								}
								infoStr += '<br><b>' +
									home.assetTypeAttribute.name +
									'</b> : ' +
									attrVal;
							}
							var spreadCoordinates = spread.assetCoordinates;
							if (spreadCoordinates.length > 0) {
								for (var index in spreadCoordinates) {
									if (index > 0) {
										disVal += distanceCal(spreadCoordinates[index].latitude, spreadCoordinates[index].longitude, spreadCoordinates[index - 1].latitude, spreadCoordinates[index - 1].longitude);
									}
								}
							}

							var userContent = document.createElement('div');

							userContent.innerHTML = '<div class="infoWindowhead">' +
								'<b>Name : </b> ' + spread.name + "<br><b>Asset Type : </b> " + spread.assetType.name + '</br>' +
								'<b>Distance : </b> ' + (parseFloat(disVal)).toFixed(2) + ' Km</br>';

							infowindow = new google.maps.InfoWindow({
								content: userContent
							});
						});
					}
					if (infowindow != null) {
						infowindow.setPosition(event.latLng);
						infowindow.open(userPaths.map);
						openedInfoWindow = infowindow;
					}
				});

				var polylinepathDuct = new google.maps.Polyline({
					path: spreadAssetCoordinates,
					geodesic: true,
					strokeColor: polylineColor,
					strokeOpacity: 1,
					strokeWeight: 2,
					fillColor: polylineColor,
					fillOpacity: 0.3,
					editable: false
				});

				polylinepath.setMap(userPaths.map);

				// polylines.push(polylinepath);
				switch (level) {
					case "level1":
						level1AssetsPolylinePath.push(polylinepath);
						break;
					case "level2":
						level2AssetsPolylinePath.push(polylinepath);
						break;
					case "level3":
						level3AssetsPolylinePath.push(polylinepath);
						break;
					case "level4":
						level4AssetsPolylinePath.push(polylinepath);
						break;
					case "level5":
						level5AssetsPolylinePath.push(polylinepath);
						break;
					default:
						polylinesForPath.push(polylinepath);
						break;
				}
			}
		}

		function drawlines(paths) {
			vm.userPathName = paths.userName;
			var userData = paths;
			if (paths.path.length > 0) {

				var data = [{
					"latitude": paths.path[0].lat,
					"longitude": paths.path[0].lng
				}];
			} else {
				var data = [{
					"latitude": Floatparse(DEFAULT_LATITUDE),
					"longitude": parseFloat(DEFAULT_LONGITUDE)
				}];
			}
			initializeMap(data, function (latlang) {
				userPaths.latlng = latlang;
				setupMap(function () {
					google.maps.event.addListener(userPaths.map, 'zoom_changed', function () {
						vm.zoomLevel = userPaths.map.getZoom();
					});
					if (paths && paths.path && paths.path.length > 0) {
						loadUserPaths(paths);
					} else {
						//						resizeMap();
						if (vm.type == "user") {
							UsersGeoFenceData.getGeoFenceData(markerUserName, function (response) {
								vm.userGeofences = [];
								vm.assetFilterGeofences = [];
								$.each(response, function (index, value) {
									if (value.geofenceType != "MASTER") {
										if (value.geofenceType == "MINI") {
											vm.userGeofences.push(value);
											value.label = value.name.substring(0, 15) + "..";
											vm.assetFilterGeofences.push(value);
											if (vm.selectedUserGeofenceId == null) {
												vm.selectedUserGeofenceId = value.id;
												vm.canUserGeofenceFilter = true;
												vm.mapUserAssets = [];
												AssetTypesByGeofenceID.get(vm.selectedUserGeofenceId, function (assetTypeVal) {
													vm.assetTypeValuesPath = assetTypeVal;
													getGroupAssetsFromESPath(vm.selectedUserGeofenceId, null, vm.mapUserAssets);
												});
											}
										} else {
											vm.userGeofences.push(value);
										}
									}
								});
							});
						} else {
							VehicleGeoFenceData.getGeoFenceData(paths.id, function (response) {
								vm.userGeofences = [];
								vm.assetFilterGeofences = [];
								$.each(response, function (index, value) {
									if (value.geofenceType != "MASTER") {
										if (value.geofenceType == "MINI") {
											vm.userGeofences.push(value);
											value.label = value.name.substring(0, 15) + "..";
											vm.assetFilterGeofences.push(value);
											if (vm.selectedUserGeofenceId == null) {
												vm.selectedUserGeofenceId = value.id;
												vm.canUserGeofenceFilter = true;
												vm.mapUserAssets = [];
												AssetTypesByGeofenceID.get(vm.selectedUserGeofenceId, function (assetTypeVal) {
													vm.assetTypeValuesPath = assetTypeVal;
													getGroupAssetsFromESPath(vm.selectedUserGeofenceId, null, vm.mapUserAssets);
												});
											}
										} else {
											vm.userGeofences.push(value);
										}
									}
								});
							});
						}
						vm.totalDistance = 0;
						$scope.totalDistanceTravelled = "0 kms";
						vm.inProgressDistance = 0
						console.log('there are no user agents found');
					}
				});


				function loadUserPaths(paths) {
					var pathIn = [];
					var pathOut = [];
					var pathArr = paths.path;
					var prevCor = null;
					var pathOther = []

					for (var i = 0; i < pathArr.length; i++) {
						if (pathArr[i].geofenceStatus == "IN") {
							pathIn.push(pathArr[i]);

							if (prevCor != null) {
								var coordinatesList = [];
								coordinatesList.push(prevCor);
								coordinatesList.push(pathArr[i]);
								drawPolylineForGeoFence(coordinatesList, userPaths.map, "green", "Inside Geofence");
							}
						} else if (pathArr[i].geofenceStatus == "OUT") {
							pathOut.push(pathArr[i]);

							if (prevCor != null) {
								var coordinatesList = [];
								coordinatesList.push(prevCor);
								coordinatesList.push(pathArr[i]);
								drawPolylineForGeoFence(coordinatesList, userPaths.map, "orange", "Outside Geofence");
							}
						} else {
							pathOther.push(pathArr[i]);

							if (prevCor != null) {
								var coordinatesList = [];
								coordinatesList.push(prevCor);
								coordinatesList.push(pathArr[i]);
								drawPolylineForGeoFence(coordinatesList, userPaths.map, "DarkBlue", "Other");
							}
						}
						prevCor = pathArr[i];
					}

					drawMarkers(paths, pathArr, pathIn, "green");
					drawMarkers(paths, pathArr, pathOut, "orange");
					if (vm.type == "user") {
						UsersGeoFenceData.getGeoFenceData(markerUserName, function (response) {
							vm.userGeofences = [];
							vm.assetFilterGeofences = [];
							$.each(response, function (index, value) {
								if (value.geofenceType != "MASTER") {
									if (value.geofenceType == "MINI") {
										vm.userGeofences.push(value);
										value.label = value.name.substring(0, 15) + "..";
										vm.assetFilterGeofences.push(value);
										if (vm.selectedUserGeofenceId == null) {
											vm.selectedUserGeofenceId = value.id;
											vm.mapUserAssets = [];
											AssetTypesByGeofenceID.get(vm.selectedUserGeofenceId, function (assetTypeVal) {
												vm.assetTypeValuesPath = assetTypeVal;
												getGroupAssetsFromESPath(vm.selectedUserGeofenceId, null, vm.mapUserAssets);
											});
											vm.canUserGeofenceFilter = true;
										}
										var coordinatesList = JSON.parse(value.coordinates);
										coordinatesList.push(coordinatesList[0]);
										drawPolylineForGeoFence(coordinatesList, userPaths.map, "blue", value.name);
									} else {
										vm.userGeofences.push(value);
										var coordinatesList = JSON.parse(value.coordinates);
										coordinatesList.push(coordinatesList[0]);
										drawPolylineForGeoFence(coordinatesList, userPaths.map, "blue", value.name);
									}
								}
							});
						});
					} else {
						VehicleGeoFenceData.getGeoFenceData(paths.id, function (response) {
							vm.userGeofences = [];
							vm.assetFilterGeofences = [];
							$.each(response, function (index, value) {
								if (value.geofenceType != "MASTER") {
									if (value.geofenceType == "MINI") {
										vm.userGeofences.push(value);
										value.label = value.name.substring(0, 15) + "..";
										vm.assetFilterGeofences.push(value);
										if (vm.selectedUserGeofenceId == null) {
											vm.selectedUserGeofenceId = value.id;
											vm.mapUserAssets = [];
											AssetTypesByGeofenceID.get(vm.selectedUserGeofenceId, function (assetTypeVal) {
												vm.assetTypeValuesPath = assetTypeVal;
												getGroupAssetsFromESPath(vm.selectedUserGeofenceId, null, vm.mapUserAssets);
											});
											vm.canUserGeofenceFilter = true;
										}
										var coordinatesList = JSON.parse(value.coordinates);
										coordinatesList.push(coordinatesList[0]);
										drawPolylineForGeoFence(coordinatesList, userPaths.map, "blue", value.name);
									} else {
										vm.userGeofences.push(value);
										var coordinatesList = JSON.parse(value.coordinates);
										coordinatesList.push(coordinatesList[0]);
										drawPolylineForGeoFence(coordinatesList, userPaths.map, "blue", value.name);
									}
								}
							});
						});
					}

					if (vm.selectedUserGeofenceId != null) {
						vm.mapUserAssets = [];
						AssetTypesByGeofenceID.get(vm.selectedUserGeofenceId, function (assetTypeVal) {
							vm.assetTypeValuesPath = assetTypeVal;
							getGroupAssetsFromESPath(vm.selectedUserGeofenceId, null, vm.mapUserAssets);
						});
					}

					Report.getReportingReport({
						id: userIdSelected,
						fromTime: vm.fromDate.getTime(),
						toTime: vm.toDate.getTime()
					}, function (response) {
						response.forEach(function (reporting) {

							if (reporting.userLatitude > 0 && reporting.userLongitude > 0) {
								var iconUrl = 'content/images/Pointer-C4.png';
								if (reporting.reportType == "CHECKIN") {
									iconUrl = 'content/images/Pointers-L4.png';
								} else {
									iconUrl = 'content/images/Pointer-C4.png';
								}
								var startMarker = new google.maps.Marker({
									map: userPaths.map,
									position: new google.maps.LatLng(
										reporting.userLatitude,
										reporting.userLongitude),
									icon: iconUrl,
									title: reporting.userLogin
								});

								google.maps.event.addListener(startMarker, 'click', function (event) {
									var date = new Date(reporting.dateTime);
									var infowindow = new google.maps.InfoWindow(
										{
											content: '<div class="infoWindowhead">' +
												'<b>Name : </b> ' + reporting.userLogin + '</br>' +
												'<b>Reporting Status : </b> ' + reporting.reportType + '</br>' +
												'<b>Reporting Time : </b> ' + date.toString() + '</br>' +
												'<b>LatLng : </b> ' + reporting.userLatitude + ', ' + reporting.userLongitude + '</br>'
										});
									infowindow.open(userPaths.map);
									infowindow.setPosition(event.latLng);
								});
							}
						});
					});

					function drawPolylineForGeoFence(latlngsToDraw, map, color, details) {
						var spreadAssetCoordinates = [];
						$.each(latlngsToDraw, function (index, value) {

							spreadAssetCoordinates.push({
								lat: value.lat,
								lng: value.lng
							});
						});

						if (spreadAssetCoordinates.length > 0) {
							var midLatLng = {
								lat: spreadAssetCoordinates[spreadAssetCoordinates.length - 1].lat,
								lng: spreadAssetCoordinates[spreadAssetCoordinates.length - 1].lng
							};
							var infowindow = new google.maps.InfoWindow(
								{
									content: '<div class="infoWindowhead">'
										+ "name"
										+ '<div class="infoWindowContent"> <b>Description :</b> '
										+ "des" + '</div></div>'
								});

							infowindow.setPosition(midLatLng);
							var lineSymbol = {
								path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
								scale: 2,
							};
							var iconStyle = [{
								icon: lineSymbol,
								offset: '100%',
								repeat: '500px'
							}];

							var ColorValue = '#ff0000';
							if (color == "red") {
								ColorValue = '#ff0000';
							} else if (color == "black") {
								ColorValue = '#000000';
							} else if (color == "blue") {
								ColorValue = '#68A8DE';
							} else if (color == "green") {
								ColorValue = '#00e658';
							} else if (color == "orange") {
								ColorValue = '#fd7a24';
							} else if (color == "DarkBlue") {
								ColorValue = '#CA902E';
							} else {
								ColorValue = '#208000';
							}

							var polylinepath = null;

							if (color == "blue") {
								polylinepath = new google.maps.Polyline({
									path: spreadAssetCoordinates,
									geodesic: true,
									strokeColor: ColorValue,
									strokeOpacity: paths.stroke.weight,
									strokeWeight: 3
								});
							} else {
								polylinepath = new google.maps.Polyline({
									path: spreadAssetCoordinates,
									geodesic: true,
									strokeColor: ColorValue,
									strokeOpacity: paths.stroke.weight,
									strokeWeight: 3,
									icons: iconStyle
								});
							}

							polylinepath.setOptions({
								strokeColor: ColorValue
							});

							google.maps.event.addListener(polylinepath, 'mouseout', function (event) {
								infowindow.close();
							});

							google.maps.event.addListener(polylinepath, 'mouseover', function (event) {
								if (openedInfoWindow != null) {
									openedInfoWindow.close();
								}
								infowindow.setContent('<div class="infoWindowhead">'
									+ '<b></b>' + details + '</br></div>'
								);

								infowindow.mousemove;
								infowindow.open(map);
								infowindow.setPosition(event.latLng);
								openedInfoWindow = infowindow;
							});
							polylinepath.setMap(map);
						}
					}
				}

				// added for calculating previous point start
				function previos_dist(i, markers) {
					var locDiff = 0;
					if (i > 0) {
						var data1 = markers[i];
						var data2 = markers[i - 1];
						var radlat1 = Math.PI * data1.lat / 180
						var radlat2 = Math.PI * data2.lat / 180
						var theta = data1.lng - data2.lng
						var radtheta = Math.PI * theta / 180
						var locDiff = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
						locDiff = Math.acos(locDiff)
						locDiff = locDiff * 180 / Math.PI
						locDiff = locDiff * 60 * 1.1515
						locDiff = locDiff * 1.609344
						locDiff = locDiff * 1000
					}
					return locDiff;
				}

				function drawMarkers(userdata, paths, userpath, colorVal) {
					var imgUrl = "content/images/dot.png";
					if (colorVal == "green") {
						imgUrl = "content/images/green_dot.png";
					} else {
						imgUrl = "content/images/orange_dot.png";
					}

					var markers = userpath;

					var infoWindow = new google.maps.InfoWindow();
					var lat_lng = new Array();

					var icon = {
						url: imgUrl, // url
						scaledSize: new google.maps.Size(5, 5),
						origin: new google.maps.Point(0, 0),
						anchor: new google.maps.Point(0, 0)
					};

					for (var i = 0; i < markers.length; i++) {
						var data = markers[i];
						var locDiff = previos_dist(i, markers);

						var myLatlng = new google.maps.LatLng(data.lat, data.lng);
						lat_lng.push(myLatlng);
						var marker = new google.maps.Marker({
							position: myLatlng,
							map: userPaths.map,
							icon: icon
						});

						(function (marker, data) {

							var logdate;
							if (data.updatedDateTime != 0) {
								logdate = new Date(data.updatedDateTime);

							} else {
								logdate = new Date(data.createdDateTime);
							}

							var logDateTime = $filter('date')(logdate, 'dd MMM HH:mm:ss');

							var travel_data = '<div class="infoWindowhead">' + logDateTime + '</div><div class="infoWindowContent"><div class="rows"><!--<p><strong>Address: </strong>' + data.address + '</p>--><p><strong>Coordinates(Lat , Long): </strong>' + data.lat.toFixed(4)
								+ ' , ' + data.lng.toFixed(4) + '</p><!--<p><strong>DistFromPrevLoc: </strong>' + locDiff.toFixed(2) + ' Meters</p>--></div></div>';

							google.maps.event.addListener(marker, "mouseover", function (e) {
								infoWindow.setContent(travel_data);
								infoWindow.open(userPaths.map, marker);
							});
							google.maps.event.addListener(marker, "mouseout", function (e) {
								infoWindow.close(userPaths.map, marker);
							});
						})(marker, data);


					}

					var infoWindow = new google.maps.InfoWindow();
					var distanceIn = userdata.distanceIn;
					var distanceOut = userdata.distanceOut;
					var distanceUnderived = userdata.distanceUnderived;

					var startMarker = new google.maps.Marker({
						map: userPaths.map,
						position: new google.maps.LatLng(paths[0].lat, paths[0].lng),
						title: 'Started here',
						icon: "content/images/green.png"
					});
					startMarker.content = '<div class="infoWindowhead">' + userdata.userName + '</div>';

					google.maps.event.addListener(startMarker, 'mouseover', function () {
						infoWindow.setContent(startMarker.content + '<div class="infoWindowContent">' + startMarker.title + '</div>');
						infoWindow.open(userPaths.map, startMarker);

					});
					google.maps.event.addListener(startMarker, 'mouseout', function () {
						infoWindow.close(userPaths.map, startMarker);
					});

					var infoWindow1 = new google.maps.InfoWindow();
					var endMarker = new google.maps.Marker({
						map: userPaths.map,
						position: new google.maps.LatLng(paths[paths.length - 1].lat, paths[paths.length - 1].lng),
						title: "Inside Geofence - " + (distanceIn).toFixed(2) + " Km and Outside Geofence " + (distanceOut).toFixed(2) + " Km",
						icon: "content/images/red.png"

					})
					if (vm.type == "user") {
						vm.totalDistance = (distanceIn + distanceOut + distanceUnderived).toFixed(2);
						$scope.totalDistanceTravelled = "   Inside Geofence - " + (distanceIn).toFixed(2) + " Km and Outside Geofence - " + (distanceOut).toFixed(2) + " Km";
						vm.inProgressDistance = (distanceUnderived).toFixed(2);
					} else {
						vm.totalDistance = (distanceIn).toFixed(2);
						$scope.totalDistanceTravelled = "";
					}


					endMarker.content = '<div class="infoWindowhead">' + userdata.userName + '</div>';

					google.maps.event.addListener(endMarker, 'mouseover', function () {

						infoWindow1.setContent(endMarker.content + '<div class="infoWindowContent">' + endMarker.title + '</div>');
						infoWindow1.open(userPaths.map, endMarker);

					});
					google.maps.event.addListener(endMarker, 'mouseout', function () {
						infoWindow1.close(userPaths.map, endMarker);
					});
				}
			});

		}

		function setupMap(callback) {
			/* var myOptions = {
				zoom: 13,
				center: userPaths.latlng,
				mapTypeId: google.maps.MapTypeId.ROADMAP
			}; */
			userPaths.map = new google.maps.Map(document.getElementById("userPath_map_canvas"), myOption(13, userPaths.latlng));
			geoLocation(userPaths.map)
			userPaths.overlay = new google.maps.OverlayView();
			userPaths.overlay.draw = function () {
			};
			userPaths.overlay.setMap(userPaths.map);
			userPaths.element = document.getElementById('userPath');
			callback();
		}

		$scope.$on('$destroy', function () {
			$interval.cancel(updateLogs);
		});



		vm.datePickerOpenStatus.createdDate = false;
		vm.datePickerOpenStatus.modifiedDate = false;
		vm.datePickerOpenStatus.serviceDate = false;

		function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;

		}

		$scope.reset = function () {
			vm.userGeofences = [];
			vm.assetFilterGeofences = [];
			vm.selectedUserGeofenceId = null;
			vm.prevSelectedUserGeofenceId = null;
			var cleratodatefromdate = vm.today();
			// cleratodatefromdate.remove();
		}

		function downloadLoadPath() {
			var data = {
				fromDate: vm.fromDate.getTime(),
				toDate: vm.toDate.getTime(),
				userId: vm.pathuserid,
				type: vm.type
			}
			LocationLogMapPath.downloadUserPath(data, function (response) {
				var a = document.createElement("a");
				a.style = "display: none";
				var file = new Blob([(response)], { type: 'application/text' });
				var fileURL = URL.createObjectURL(file);
				a.href = fileURL;
				a.download = "TravelledPath.kml";
				a.click();
				window.URL.revokeObjectURL(fileURL);
			});
		}

		function filterAssetsLocationOnMapView() {
			vm.allGeofences = []
			if (vm.adminFilter) {
				GeofenceIDs.getAll(function (response) {
					response.forEach(function (geofence) {
						if (geofence.name != 'Bangalore') {
							geofence.label = geofence.name.substring(0, 15) + "..";
							vm.allGeofences.push(geofence);
							if (vm.selectedGeofenceId == null) {
								vm.selectedGeofenceId = geofence.id;
							}
						}
					});
				});
			} else {
				UserGeofences.getAll(function (response) {
					response.forEach(function (geofence) {
						if (geofence.name != 'Bangalore') {
							geofence.label = geofence.name.substring(0, 15) + "..";
							vm.allGeofences.push(geofence);
							if (vm.selectedGeofenceId == null) {
								vm.selectedGeofenceId = geofence.id;
							}
						}
					});
				});
			}
			/* $("#mapFilterLocationModal").show();
			noDataText = document.getElementById("noData");
			noDataText.style.display = "none"; */
			vm.prevSelectedGeofenceId = vm.selectedGeofenceId;
		}

		function closeMapLocationFilterModal() {
			$("#mapFilterLocationModal").removeClass("in");
			$(".modal-backdrop").remove();
			$("#mapFilterLocationModal").hide();
			vm.selectedGeofenceId = vm.prevSelectedGeofenceId;
		}

		function filterAssetsLoadOnMapView() {
			$("#mapFilterLoadPathModal").show();
			noDataText = document.getElementById("noData");
			noDataText.style.display = "none";
			vm.prevSelectedUserGeofenceId = vm.selectedUserGeofenceId;
		}

		function closeMapLoadPathFilterModal() {
			$("#mapFilterLoadPathModal").removeClass("in");
			$(".modal-backdrop").remove();
			$("#mapFilterLoadPathModal").hide();
			vm.selectedUserGeofenceId = vm.prevSelectedUserGeofenceId;
		}



		function saveLoadPathFilter() {
			/* $("#mapFilterLoadPathModal").removeClass("in");
			$(".modal-backdrop").remove();
			$("#mapFilterLoadPathModal").hide(); */
			onChangeDate(vm.pathuserid);

			vm.mapUserAssets = [];
			AssetTypesByGeofenceID.get(vm.selectedUserGeofenceId, function (assetTypeVal) {
				vm.assetTypeValuesPath = assetTypeVal;
				getGroupAssetsFromESPath(vm.selectedUserGeofenceId, null, vm.mapUserAssets);
			});

			vm.prevSelectedUserGeofenceId = vm.selectedUserGeofenceId;

		}

		function clearMarkers(markersList) {
			for (var i = 0; i < markersList.length; i++) {
				markersList[i].setMap(null);
			}
		}

		function clearPolylines(polylinesList) {
			for (var i = 0; i < polylinesList.length; i++) {
				polylinesList[i].setMap(null);
			}
		}


		function saveLocationFilter() {
			// if (vm.selectedLogs === "ALL") {
			// 	vm.all();
			// } else if (vm.selectedLogs === "ACTIVE") {
			// 	vm.active();
			// } else if (vm.selectedLogs === "INACTIVE") {
			// 	vm.inactive();
			// } else if (vm.selectedLogs === "IDLE") {
			// 	vm.idle();
			// }

			vm.mapAssets = [];
			AssetTypesByGeofenceID.get(vm.selectedGeofenceId, function (assetTypeVal) {
				vm.assetTypeValues = assetTypeVal;
				getGroupAssetsFromES(vm.selectedGeofenceId, null, vm.mapAssets);
			});

			vm.prevSelectedGeofenceId = vm.selectedGeofenceId;

			$("#mapFilterLocationModal").removeClass("in");
			$(".modal-backdrop").remove();
			$("#mapFilterLocationModal").hide();
		}

		function getGroupLocationAssetsFromES(geofenceIdVal, lastAssetId, mapAssetLists, map) {
			AssetGroupES.get(geofenceIdVal, lastAssetId, function (data) {
				vm.mapAssets = mapAssetLists;
				data.assets.forEach(function (asset) {
					var tmpAsset = {};

					tmpAsset.assetCoordinates = asset.assetCoordinates;
					tmpAsset.assetTypeName = asset.assetTypeName;
					tmpAsset.assetTypeColor = asset.assetColor;
					tmpAsset.assetTypeImagePath = asset.assetTypeImagePath;
					tmpAsset.assetTypeLayout = asset.assetTypeLayout;
					tmpAsset.id = asset.id;
					tmpAsset.name = asset.name;

					vm.mapAssets.push(tmpAsset);
				});
				drawLatestLocationAssets(vm.mapAssets, map);
				if (data.lastId != null) {
					getGroupLocationAssetsFromES(geofenceIdVal, data.lastId, vm.mapAssets, map);
				}
			});
		}

		function drawLatestLocationAssets(response, map) {
			if (response) {
				initializeLocationAssetMap(response, function (latlang) {
					locations.latlng = latlang;
					$.each(response, function (index, value) {
						if (value.assetTypeLayout == null) {
							value.assetTypeName = value.assetType.name;
							value.assetTypeLayout = value.assetType.layout;
							value.assetTypeImagePath = value.assetType.imagePath;
						}
						if (value.assetTypeLayout != null && value.assetTypeLayout == 'FIXED') {
							if (value.assetCoordinates.length > 1) {
								drawPolyline(value);
								var value1 = value;
								value1.assetCoordinates = [value.assetCoordinates[value.assetCoordinates.length - 1]];

								drawFixedLocationAsset(value1, false, map);

							} else {
								drawFixedLocationAsset(value, false, map);
							}
						}
						if (value.assetTypeLayout != null && value.assetTypeLayout == 'SPREAD') {
							drawPolyline(value, map);
						}

					});
				});
			}
		}

		function initializeLocationAssetMap(latlangs, callback) {

			if (latlangs && latlangs.length > 0) {
				var latVal = null;
				var lngVal = null;
				latlangs.forEach(function (value) {
					if (latVal == null && lngVal == null) {
						if (value.assetCoordinates.length > 0) {
							latVal = value.assetCoordinates[value.assetCoordinates.length - 1].latitude;
							lngVal = value.assetCoordinates[value.assetCoordinates.length - 1].longitude;
						}
					}
				});
				if (latVal != null && lngVal != null) {
					latlng = new google.maps.LatLng(latVal, lngVal);
					callback(latlng);
				}
			} else {
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(function (
						position) {

						latlng = new google.maps.LatLng(
							position.coords.latitude,
							position.coords.longitude);
						callback(latlng);
					}, function () {
						callback(new google.maps.LatLng(DEFAULT_LATITUDE, DEFAULT_LONGITUDE));
					});
				} else {
					callback(new google.maps.LatLng(DEFAULT_LATITUDE, DEFAULT_LONGITUDE));
				}
			}
		}

		function drawPolyline(spread, map) {

			var spreadAssetCoordinates = [];
			var spreadColor = 0;
			var spreadWidth = 0;
			var spreadOpacity = 0;

			vm.assetTypes.forEach(function (assetType) {
				if (assetType.name == spread.assetTypeName) {
					spreadColor = assetType.colorcode;
					spreadWidth = assetType.lineWidth * 0.05;
					spreadOpacity = assetType.transparency * 0.01;
				}
			});
			$.each(spread.assetCoordinates, function (index, value) {

				spreadAssetCoordinates.push({
					lat: value.latitude,
					lng: value.longitude
				});
			});
			if (spreadAssetCoordinates.length > 1) {
				var midLatLng = {
					lat: spread.assetCoordinates[spread.assetCoordinates.length - 1].latitude,
					lng: spread.assetCoordinates[spread.assetCoordinates.length - 1].longitude
				};

				var polylineColor = '';
				var distanceFromPOP = '';
				var fiberCoreVal = '';
				var infowindow = null;
				if (spread.assetTypeLayout == "SPREAD") {
					polylineColor = spreadColor;
				}
				var polylineWeight = spreadWidth;
				var strokeOpacityVal = spreadOpacity;

				var polylinepath = new google.maps.Polyline({
					path: spreadAssetCoordinates,
					geodesic: true,
					strokeColor: polylineColor,
					strokeOpacity: strokeOpacityVal,
					strokeWeight: polylineWeight,
					fillColor: polylineColor,
					fillOpacity: 0.3,
					editable: false,
				});

				google.maps.event.addListener(polylinepath, 'click', function (event) {
					if (openedInfoWindow != null) {
						openedInfoWindow.close();
					}
					var disVal = 0;
					var cLocations = [];

					if (spread.description == null || spread.description == undefined) {
						spread.description = "";
					}

					if (spread.assetTypeLayout == "SPREAD") {
						AssetByID.get(spread.id, function (assetVal) {
							spread = assetVal;
							var infoStr = "";
							var assetTypeAttributeValuesList = spread.assetTypeAttributeValues;
							assetTypeAttributeValuesList.sort(function (a, b) {
								var nameA = a.assetTypeAttribute.name.toLowerCase(),
									nameB = b.assetTypeAttribute.name.toLowerCase()
								if (nameA < nameB) // sort string ascending
									return -1
								if (nameA > nameB)
									return 1
								return 0 // default return value (no sorting)
							});
							for (var i = 0; i < assetTypeAttributeValuesList.length; i++) {
								var home = assetTypeAttributeValuesList[i];

								var attrVal = "";
								if (home.attributeValue != null) {
									attrVal = home.attributeValue;
								}
								infoStr += '<br><b>' +
									home.assetTypeAttribute.name +
									'</b> : ' +
									attrVal;
							}
							var spreadCoordinates = spread.assetCoordinates;
							if (spreadCoordinates.length > 0) {
								for (var index in spreadCoordinates) {
									if (index > 0) {
										disVal += distanceCal(spreadCoordinates[index].latitude, spreadCoordinates[index].longitude, spreadCoordinates[index - 1].latitude, spreadCoordinates[index - 1].longitude);
									}
								}
							}

							var userContent = document.createElement('div');

							userContent.innerHTML = '<div class="infoWindowhead">' +
								'<b>Name : </b> ' + spread.name + "<br><b>Asset Type : </b> " + spread.assetType.name + '</br>' +
								'<b>Distance : </b> ' + (parseFloat(disVal)).toFixed(2) + ' Km</br>';

							infowindow = new google.maps.InfoWindow({
								content: userContent
							});
						});
					}
					if (infowindow != null) {
						infowindow.setPosition(event.latLng);
						infowindow.open(map);
						openedInfoWindow = infowindow;
					}
				});

				polylinepath.setMap(map);

				polylines.push(polylinepath);
			}
		}

		function drawFixedLocationAsset(fixed, hasParent, map) {

			$.each(fixed.assetCoordinates, function (index, value1) {
				if (openedInfoWindow != null) {
					openedInfoWindow.close();
				}

				var infoWindow = new google.maps.InfoWindow();


				var iconUrl = "";
				var isFixedAsset = false;
				iconUrl = fixed.assetTypeImagePath;
				isFixedAsset = true;

				var iconSize = 8;
				if (vm.zoomLevel == 10) {
					iconSize = 8;
				} else if (vm.zoomLevel == 11) {
					iconSize = 16;
				}

				if (isFixedAsset) {
					iconSize = 24;
				}

				var startMarker = new google.maps.Marker({
					map: map,
					position: new google.maps.LatLng(
						value1.latitude,
						value1.longitude),
					title: fixed.name,
					icon: {
						scaledSize: new google.maps.Size(
							iconSize, iconSize),
						url: iconUrl,
						anchor: new google.maps.Point(
							iconSize / 2, iconSize / 2),
					},
				});


				markers.push(startMarker);

				var infoWindow2 = new google.maps.InfoWindow();
				var infoStr = "";
				var otdr = "";

				var userContent = document.createElement('div'),
					button;

				userContent.innerHTML = '<div class="infoWindowhead">' + startMarker.title +
					'</div><div class="infoWindowContent">' +
					'<b>Lat</b> :' +
					fixed.assetCoordinates[fixed.assetCoordinates.length - 1].latitude +
					'<br><b>Long</b> :' +
					fixed.assetCoordinates[fixed.assetCoordinates.length - 1].longitude +
					'<br>' + infoStr + '</div>';

				google.maps.event.addListener(startMarker,
					'dblclick',
					function () {
						infoWindow2.close();
					});

				var activeInfoWindow;
				var infoWindow2 = new google.maps.InfoWindow({
					content: userContent
				});
				// add listener on InfoWindow for click
				// event
				google.maps.event.addListener(startMarker, 'click', function () {
					AssetByID.get(fixed.id, function (response) {
						var infoWindow2 = new google.maps.InfoWindow();
						var infoStr = "";
						var otdr = "";
						var assetTypeAttributeValuesList = response.assetTypeAttributeValues;
						assetTypeAttributeValuesList.sort(function (a, b) {
							var nameA = a.assetTypeAttribute.name.toLowerCase(),
								nameB = a.assetTypeAttribute.name.toLowerCase()
							if (nameA < nameB) // sort string ascending
								return -1
							if (nameA > nameB)
								return 1
							return 0 // default return value (no sorting)
						});
						for (var i = 0; i < assetTypeAttributeValuesList.length; i++) {
							var home = assetTypeAttributeValuesList[i];
							infoStr += '<br><b>' +
								home.assetTypeAttribute.name +
								'</b> : ' +
								home.attributeValue;
							otdr = home;
						}

						var userContent = document.createElement('div');
						userContent.innerHTML = '<div class="infoWindowhead">' + "<b>Name</b> : " + startMarker.title +
							'</div><div class="infoWindowContent">' +
							"<b>Asset Type</b> : " + fixed.assetTypeName +
							"<br><b>Latitude</b> :" +
							fixed.assetCoordinates[fixed.assetCoordinates.length - 1].latitude +
							'<br><b>Longitude</b> :' +
							fixed.assetCoordinates[fixed.assetCoordinates.length - 1].longitude +
							'<br>' + infoStr + '</div>';

						var infoWindow2 = new google.maps.InfoWindow({
							content: userContent
						});
						if (prevInfoWindow != null)
							prevInfoWindow.close();
						infoWindow2.open(map, startMarker);
						setTimeout(function () { infoWindow2.close(); }, 5000);

						activeInfoWindow = infoWindow2;
						prevInfoWindow = infoWindow2;
					});

				});
				openedInfoWindow = infoWindow2;
			});
		}

		function distanceCal(lat1, lon1, lat2, lon2) {
			var theta = lon1 - lon2;
			var dist = Math.sin(degrees_to_radians(lat1)) * Math.sin(degrees_to_radians(lat2)) +
				Math.cos(degrees_to_radians(lat1)) * Math.cos(degrees_to_radians(lat2)) * Math.cos(degrees_to_radians(theta));
			dist = Math.acos(dist);
			dist = radians_to_degrees(dist);
			dist = dist * 60 * 1.1515;
			dist = dist * 1.609344;
			if (isNaN(dist)) {
				return 0.0;
			} else {
				return (dist);
			}
		}

		function degrees_to_radians(degrees) {
			var pi = Math.PI;
			return degrees * (pi / 180);
		}

		function radians_to_degrees(radians) {
			var pi = Math.PI;
			return radians * (180 / pi);
		}

		function showZoomLocation() {
			var latlng = vm.getLocationCoordinate.split(",");
			if (gotoMarker != null) {
				gotoMarker.setMap(null);
			}
			if (vm.getLocationCoordinate != null) {
				var centerlatlng = {
					lat: parseFloat(latlng[0]),
					lng: parseFloat(latlng[1])
				};
					locations.map.setCenter(centerlatlng);
					locations.map.setZoom(18);
					var startMarker = new google.maps.Marker({
						map: locations.map,
						position: centerlatlng,
					});
				gotoMarker = startMarker;
				$("#modalLatLongs").removeClass("in");
				$(".modal-backdrop").remove();
				$("#modalLatLongs").hide();
			}
		}
		function gotoLatitute() {
			$("#modalLatLongs").show();
			//  noDataText = document.getElementById("noData");
			// noDataText.style.display = "none"; 
		}

		function gotoLatituteCity() {
			$("#modalLatLongs").show();
			// noDataText = document.getElementById("noData");
			// noDataText.style.display = "none";
		}
		function closeGotoLatitute(id) {
			$("#" + id).removeClass("in");
			$(".modal-backdrop").remove();
			$("#" + id).hide();
		}
		function closeGotoLatituteCity() {
			$("#modalLatLongs").removeClass("in");
			$(".modal-backdrop").remove();
			$("#modalLatLongs").hide();
		}
		function geoLocation(map) {
			var infoWindow = new google.maps.InfoWindow();
			var locationButton = document.createElement("button");
			locationButton.setAttribute("title", "Locate Me");
			locationButton.classList.add("custom-map-control-button");
			var geoIcon = document.createElement("i");
			geoIcon.classList.add("fa");
			geoIcon.classList.add("fa-crosshairs");
			geoIcon.classList.add("geo");
			locationButton.appendChild(geoIcon);
			map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
				locationButton
			);

			var goTo = document.createElement("button");
			goTo.setAttribute("title", "Location Coordinates");
			goTo.classList.add("custom-map-control-button");
			var gotoIcon = document.createElement("i");
			gotoIcon.classList.add("fa");
			gotoIcon.classList.add("fa-location-arrow");
			gotoIcon.classList.add("geo");
			goTo.appendChild(gotoIcon);
			map.controls[google.maps.ControlPosition.RIGHT_TOP].push(goTo);

			/* var zoomLabel = document.createElement("span");
			zoomLabel.setAttribute("title", "Zoom");
			zoomLabel.classList.add("custom-map-control-button");
			var textnode = document.createTextNode("Zoom :"+vm.zoomLevel);
			zoomLabel.appendChild(textnode);
			map.controls[google.maps.ControlPosition.LEFT_TOP].push(
				zoomLabel
			);  */

			goTo.addEventListener("click", function () {
				gotoLatitute();
			});
			var measureTool = document.createElement("button");
			measureTool.setAttribute("title", "Measure Distance");
			measureTool.classList.add("custom-map-control-button");
			var measureToolIcon = document.createElement("i");
			measureToolIcon.classList.add("fa");
			measureToolIcon.classList.add("fa-arrows-h");
			measureToolIcon.classList.add("ruler");
			measureTool.appendChild(measureToolIcon);
			map.controls[google.maps.ControlPosition.RIGHT_TOP].push(measureTool);
			measureTool.addEventListener("click", function () {

				if (measureEnabled) {
					google.maps.event.removeListener(vm.clickListener);
					totalDistance = 0
					clearMeasureTool(distanceMarkers)
					clearMeasureTool(pointerMarkers)
					clearMeasureTool(arrpolyMeasureTool)
					clearMeasureTool(totalDistanceMarkers)
					distanceMarkers = []
					totalDistanceMarkers = []
					pointerMarkers = []
					arrpolyMeasureTool = []
					measureEnabled = false
					measureTool.setAttribute("title", "Measure Distance");
					measureToolIcon.classList.remove("fa-times");
					measureToolIcon.classList.add("fa-arrows-h");
				} else {
					measureEnabled = true
					polyMeasureTool = new google.maps.Polyline({
						strokeColor: 'black',
						strokeOpacity: 1.0,
						strokeWeight: 3
					});
					polyMeasureTool.setMap(locations.map);
					arrpolyMeasureTool.push(polyMeasureTool)

					vm.clickListener = locations.map.addListener('click', addLatLng);
					measureTool.setAttribute("title", "Clear Distance");
					measureToolIcon.classList.remove("fa-arrows-h");
					measureToolIcon.classList.add("fa-times");
				}
			});

			locationButton.addEventListener("click", function () {
				// Try HTML5 geolocation.
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(
						function (position) {
							var pos = {
								lat: position.coords.latitude,
								lng: position.coords.longitude,
							};

							infoWindow.setPosition(pos);
							infoWindow.setContent("Location found.");
							infoWindow.open(map);
							// var image = {
							//     url: "content/images/geoLocation.png", // url
							//     scaledSize: new google.maps.Size(25, 25), // scaled size
							//     origin: new google.maps.Point(0, 0), // origin
							//     anchor: new google.maps.Point(0, 0), // anchor
							// };
							// var markerGeo = new google.maps.Marker({
							//     position: pos,
							//     icon: image,
							// });
							// map.setZoom(20);
							// markerGeo.setMap(map);
							map.setZoom(18);
							map.setCenter(pos);
						},
						function () {
							handleLocationError(
								true,
								infoWindow,
								map.getCenter()
							);
						},
						{
							enableHighAccuracy: true,
							timeout: 5000,
							maximumAge: 0,
						}
					);
				} else {
					// Browser doesn't support Geolocation
					handleLocationError(false, infoWindow, map.getCenter());
				}
			});

			function handleLocationError(
				browserHasGeolocation,
				infoWindow,
				pos
			) {
				infoWindow.setPosition(pos);
				infoWindow.setContent(
					browserHasGeolocation
						? "Error: The Geolocation service failed."
						: "Error: Your browser doesn't support geolocation."
				);
				infoWindow.open(map);
			}
		}
		function clearMeasureTool(arr) {
			for (var i = 0; i < arr.length; i++) {
				arr[i].setMap(null)
			}
		}

		function addLatLng(event) {
			var path = polyMeasureTool.getPath();
			path.push(event.latLng);
			addPointer(event.latLng);
			var point1, point2
			for (var i = 0; i < path.getLength() - 1; i++) {
				point1 = path.getAt(i);
				point2 = path.getAt(i + 1);
				segmentDistance = google.maps.geometry.spherical.computeDistanceBetween(point1, point2);
				// Add distance marker
				midpoint = google.maps.geometry.spherical.interpolate(point1, point2, 0.5);

			}
			if (path.getLength() > 1) {
				totalDistance += segmentDistance;
				var marker = new google.maps.Marker({
					position: midpoint,
					map: locations.map,
					icon: {
						url: 'content/images/iconTransparent.png',
					},
					label: {
						color: "black",
						fontWeight: "bold",
						fontSize: "14px",
						text: formatDistance(segmentDistance),
					},
				});
				distanceMarkers.push(marker);

				var totalDistanceMarker = new google.maps.Marker({
					position: point2,
					map: locations.map,
					icon: {
						url: 'content/images/iconTransparent.png',
					},
					label: {
						color: "black",
						fontWeight: "bold",
						fontSize: "14px",
						text: "Total : " + formatDistance(totalDistance),
					},
				});
				totalDistanceMarkers.push(totalDistanceMarker);
			}
		}
		function addPointer(location, map) {
			var pointerIcon = {
				path: google.maps.SymbolPath.CIRCLE,
				scale: 5,
				strokeColor: 'black',
				fillColor: 'white',
				fillOpacity: 1,
				strokeWeight: 2
			};

			var pointer = new google.maps.Marker({
				position: location,
				map: map,
				icon: pointerIcon
			});
			pointerMarkers.push(pointer);
		}

		function formatDistance(distance) {
			// Convert meters to kilometers
			var km = distance / 1000;
			// Show in meters if less than 1 km, otherwise show in kilometers
			if (km < 1) {
				return distance.toFixed(0) + ' m';
			} else {
				return km.toFixed(2) + ' km';
			}
		}
		function initializeMap(latlangs, callback) {
			if (latlangs && latlangs.length > 0) {
				latlng = new google.maps.LatLng(latlangs[0].latitude, latlangs[0].longitude);
				callback(latlng);
			} else {
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(function (position) {
						google.maps.event.trigger(locations.map, "resize");
						var centerlatlng = {
							lat: parseFloat(latlng[0]),
							lng: parseFloat(latlng[1])
						};
						try {
							locations.map.setCenter(centerlatlng);
						} catch (err) {
							console.log(err);
						}
					}, function () {
						setTimeout(function () {
							google.maps.event.trigger(locations.map, "resize");
							var centerlatlng = {
								lat: parseFloat(latlng[0]),
								lng: parseFloat(latlng[1])
							};
							try {
								locations.map.setCenter(centerlatlng);
							} catch (err) {
								console.log(err);
							}
						}, 100);
					});
				} else {
					setTimeout(function () {
						google.maps.event.trigger(locations.map, "resize");
						var centerlatlng = {
							lat: parseFloat(latlng[0]),
							lng: parseFloat(latlng[1])
						};
						try {
							locations.map.setCenter(centerlatlng);
						} catch (err) {
							console.log(err);
						}
					}, 100);
				}
			}
		}

	}
})();