(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("EntityDumpController", EntityDumpController);

    EntityDumpController.$inject = [
        "Report",
        "myservice",
        "$interval",
        "$scope",
        "DumpRequest",
        "pagingParams",
        "paginationConstants",
        "AlertService",
        "ParseLinks",
        "$state"
    ];

    function EntityDumpController(
        Report,
        myservice,
        $interval,
        $scope,
        DumpRequest,
        pagingParams,
        paginationConstants,
        AlertService,
        ParseLinks,
        $state
    ) {
        var vm = this;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        $scope.search = pagingParams.search;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.filterSearch = filterSearch;
        vm.highlightRow = false
        if(myservice.getValue() == 'true'){
            vm.highlightRow = true;
        }
       
        
        // callReportApi()
        filterSearch();

        vm.refresh = function(){
            filterSearch();
        }

        function callReportApi(){
            Report.getListEntity(function (response){
                if(response.length){
                    vm.displayList = response.reverse()
                }
            })

            
        }
        
        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: $scope.search
            });
        }

        function filterSearch() {
            if ($scope.search != null && $scope.search != "" && !angular.isUndefined($scope.search)) {
                var str = $scope.search+"";
                str = str.replace('/', 'slash');
                DumpRequest.query({
                    userId: str,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);

                function sort() {
                    var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                    if (vm.predicate !== 'id') {
                        //  result.push('id');
                    }
                    return result;
                }

                function onSuccess(data, headers) {
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.displayList = data;
                    vm.page = pagingParams.page;
                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }
            } else {
                loadAll();
            }
        }

        function loadAll() {
            DumpRequest.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    //  result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.displayList = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        var myInterval = $interval(function() {
            // Code to be executed periodically
            // callReportApi()
            filterSearch();
          }, 60000); // Interval duration in milliseconds
        
          // Listen for the $destroy event to clear the interval when the controller is destroyed
          $scope.$on('$destroy', function() {
            // Clear the interval when the controller is destroyed
            if (angular.isDefined(myInterval)) {
              $interval.cancel(myInterval);
            }
          });
        
        vm.expand = function(entity) {
            vm.displayList.forEach(function (item) {
                item.expand = false;
            });

            if (vm.toggle == entity.id) {
                vm.toggle = undefined;
            } else {
                entity.expand = true;
                vm.toggle = entity.id;

                Report.getEntityDetails(entity.id,function (response){
                    vm.expandResponse = response
                })

            }


        }

        vm.download = function(imgId,id,name){
            var temp = "Report"
            Report.downloadZip(id,imgId,function (data){
                var a = document.createElement("a");
               var file = new Blob([data], { type: 'application/zip' });

               var fileURL = URL.createObjectURL(file);
               a.href = fileURL;
               a.download = name ? name + ".zip" : temp + ".zip";
               a.click();
               window.URL.revokeObjectURL(fileURL);
            })
        }
    }
})();
