(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("EntityDumpDialogController", EntityDumpDialogController);

        EntityDumpDialogController.$inject = [
        "$timeout",
        "$state",
        "$scope",
        "$localStorage",
        "Report",
        "AllGeofenceIDs",
        "AllCaseTypes",
        "UserMasterGeofences",
        "MiniGeofencesByMasterID",
        "AssetType",
        "BaseUrl",
        "myservice"
    ];
    
    function EntityDumpDialogController(
        $timeout,
        $state,
        $scope,
        $localStorage,
        Report,
        AllGeofenceIDs,
        AllCaseTypes,
        UserMasterGeofences,
        MiniGeofencesByMasterID,
        AssetType,
        BaseUrl,
        myservice
    ) {
        var vm = this;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        var dateFormat = "dd EEE HH:mm";
        var date = "dd EEE";
        vm.fromTime = 0;
        vm.toTime = 0;
        //vm.today = today();
        vm.searchMini = "";
        vm.report;
        vm.geofences = [];
        vm.userMasterGeofences = [];
        vm.geofenceMini;
        vm.selectedMasterGeofenceId = 0;
        vm.selectedGeofenceId;
        vm.entity;
        vm.showMessage = false;
        vm.showMessageError = false;
        vm.selecterange = selectRange;
        vm.calculateToDate = calculateToDate;
        vm.calculateFromDate = calculateFromDate;
        vm.trackingId;
        //vm.submit = true;
        var date = new Date();
        $scope.optionsTo = {
			maxDate: date.setDate(date.getDate())
		};
        $scope.optionsFrom = {
			maxDate: date.setDate(date.getDate())
		};

        $(".select2").select2(); // Added search for select box
        function today() {
            var today = new Date();
            vm.toDate = new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate()
            );
            vm.fromDate = new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate()
            );
            vm.fromDate.setHours(0, 0, 0, 0);
            vm.fromTime = vm.fromDate.getTime();
            vm.toDate.setHours(23, 59, 59, 999);
            vm.toTime = vm.toDate.getTime();
        }
        vm.users = loadPage();
        vm.toDate = null
        vm.fromDate = null
        $(".select2").change(function () {
            vm.showMessage = false;
            vm.showMessageError = false;
            //vm.submit = true;
        });

        function loadPage() {
            // AllGeofenceIDs.getAll(function (response) {
            // response.forEach(function (geofence) {
            // vm.geofences.push({
            // name: geofence.name,
            // id: geofence.id,
            // geofenceTypeMini: geofence.geofenceType,
            // nameZone: geofence.name + " - " + geofence.geofenceType,
            // });

            // vm.geofenceMini = vm.geofences.filter(function onlyMINI(a) {
            // return a.geofenceTypeMini == "MINI";
            // });
            // });
            // });

            AssetType.query(
                {
                    page: 0,
                    size: 100,
                    sort: sort(),
                },
                onSuccess,
                onError
            );
            function sort() {
            }
            function onSuccess(data, headers) {
                vm.assetTypesData = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }

            vm.userMasterGeofences = [];
            UserMasterGeofences.getAll(function (response) {
                vm.userMasterGeofences = response;
                vm.selectedMasterGeofenceId = vm.userMasterGeofences[0].id.toString();
                vm.geofenceMini = [];
                MiniGeofencesByMasterID.get(
                    vm.selectedMasterGeofenceId,
                    function (response) {
                        response.forEach(function (value) {
                            vm.geofenceMini.push(value);
                        });
                    }
                );
            });
            AllCaseTypes.getAll(function (response) {
                vm.caseTypes = response;
            });
        }

        /* $scope.submitChange = function(){
//vm.submit = true;
vm.showMessageError= false
vm.showMessage= false
} */

        function openCalendar(date) {
            //vm.submit = true;
            vm.showMessageError = false;
            vm.showMessage = false;
            vm.datePickerOpenStatus[date] = true;
        }

        function selectRange(range) {
            if (range == "week") {
                vm.showMessageError = false;
                vm.showMessage = false;
                //vm.submit = true;
                var date = new Date();
                date.setHours(23, 59, 59, 999);
                vm.toTime = date.getTime();
                date.setHours(0, 0, 0, 0);
                date.setDate(date.getDate() - 7);
                vm.fromTime = date.getTime();
            } else if (range == "month") {
                vm.showMessageError = false;
                vm.showMessage = false;
                //vm.submit = true;
                var date = new Date();
                date.setHours(23, 59, 59, 999);
                vm.toTime = date.getTime();
                date.setHours(0, 0, 0, 0);
                date.setMonth(date.getMonth() - 1);
                vm.fromTime = date.getTime();
            } else if (range == "year") {
                vm.showMessageError = false;
                vm.showMessage = false;
                //vm.submit = true;
                var date = new Date();
                date.setHours(23, 59, 59, 999);
                vm.toTime = date.getTime();
                date.setHours(0, 0, 0, 0);
                date.setYear(date.getFullYear() - 1);
                vm.fromTime = date.getTime();
            }
        }

        function calculateFromDate() {
            $scope.optionsTo = {
                minDate: vm.fromDate.setDate(vm.fromDate.getDate()),
                maxDate: date.setDate(date.getDate())
            };
           vm.fromDate.setHours(0, 0, 0, 0);
           vm.fromTime = vm.fromDate.getTime();
        }

        $scope.onMasterChange = function () {
            vm.showMessageError = false;
            vm.showMessage = false;
            //vm.submit = true;
            vm.geofenceMini = [];
            MiniGeofencesByMasterID.get(
                vm.selectedMasterGeofenceId,
                function (response) {
                    response.forEach(function (value) {
                        vm.geofenceMini.push(value);
                    });
                }
            );
        };

        vm.assetTypes = []
        $scope.assetLayoutChange = function (){
            if(vm.entityLayout == "FIXED"){
                vm.assetTypes = []
                vm.assetTypesData.filter(function(val){
                    if(val.layout  == "FIXED"){
                        vm.assetTypes.push({name: val.name, id: val.id})
                    }
                })
            }else{
                vm.assetTypes = []
                vm.assetTypesData.filter(function(val){
                    if(val.layout  == "SPREAD"){
                        vm.assetTypes.push({name: val.name, id: val.id})
                    }
                })
            }
        }

        function calculateToDate() {
            $scope.optionsFrom = {
                maxDate: vm.toDate.setDate(vm.toDate.getDate())
            };
            vm.toDate.setHours(23, 59, 59, 999);
            vm.toTime = vm.toDate.getTime();
        }

        $scope.entityDump = function () {
            //vm.submit = false;
            if (vm.entity == "Assets") {
                var geofenceDTO = vm.selectedGeofenceId;
                /*var geoList = [];
                geoList.push(geofenceDTO);*/
                if(vm.entityFileFormat == "EXCEL"){
                    BaseUrl.getBaseUrl(function(baseurl){
                        var request = new XMLHttpRequest();
                        request.open('GET', baseurl.stringValue+"api/assets-export-excel/"+geofenceDTO[0].id+"/"+vm.selectedAssettypeId[0].id, true);
                        request.setRequestHeader('Authorization', 'Bearer '+$localStorage.authenticationToken);
                        request.responseType = 'blob';
            
                        request.onload = function(e) {
                            if (this.status === 200) {
                                var blob = this.response;
                                if(blob.size > 0){
                                    if(window.navigator.msSaveOrOpenBlob) {
                                        window.navigator.msSaveBlob(blob, 'test.xlsx');
                                    }
                                    else{
                                        var downloadLink = window.document.createElement('a');
                                        var contentTypeHeader = request.getResponseHeader("Content-Type");
                                        downloadLink.href = window.URL.createObjectURL(new Blob([blob], { 
                                        type: contentTypeHeader }));
                                        downloadLink.download = 'AssetExportExcel.xlsx';
                                        document.body.appendChild(downloadLink);
                                        downloadLink.click();
                                        document.body.removeChild(downloadLink);
                                    }
                                }
                            }
                        };
                        request.send();
                    });
                } else {
                    var geofenceId = geofenceDTO;
                    if(geofenceDTO.length == 0){
                        geofenceId = [];
                        geofenceId.push({"id":parseInt(vm.selectedMasterGeofenceId)});
                    }

                    var selectedAssettypeId = 0;
                    if(vm.selectedAssettypeId != undefined || vm.selectedAssettypeId != null){
                        selectedAssettypeId = vm.selectedAssettypeId[0].id;    
                    }
                    
                    Report.emailReport(
                        {
                            entityName: vm.entity,
                            geofences: geofenceId,
                            entityLayout : vm.entityLayout,
                            entityTypeId : selectedAssettypeId,
                            fileFormat : vm.entityFileFormat
                        },
                        function (response) {
                            vm.showMessageError = false;
                            vm.showMessage = true;
                            vm.trackingId = response.id
                            $timeout(function () {
                                $state.go('entity-dump');
                            }, 2000);  
                            myservice.setValue("true");
                        },
                        function (e) {
                            vm.errorMessage = e.data.message;
                            vm.showMessage = false;
                            vm.showMessageError = true;
                            $timeout(function () {
                                vm.showMessageError = false;
                            }, 4000);
                        }
                    );
                    
                }
            } else if (vm.entity == "Cases") {
                var selectedCaseType = 0;
                if (
                    vm.selectedCaseType != undefined &&
                    vm.selectCaseType != "all"
                ) {
                    selectedCaseType = vm.selectedCaseType.id;
                }

                Report.emailReport(
                    {
                        entityName: vm.entity,
                        entityStatus: vm.entityStatus,
                        caseTypeId: selectedCaseType,
                        entityTypeId: selectedCaseType,
                        reqFromDate: vm.fromTime,
                        reqToDate: vm.toTime,
                    },
                    function (response) {
                        vm.showMessageError = false;
                        vm.showMessage = true;
                        vm.trackingId = response.id
                        $timeout(function () {
                            $state.go('entity-dump');
                        }, 2000); 
                        myservice.setValue("true");
                    },
                    function (e) {
                        vm.errorMessage = e.data.message;
                        vm.showMessage = false;
                        vm.showMessageError = true;
                        $timeout(function () {
                            vm.showMessageError = false;
                        }, 4000);
                    }
                );
            } else if (vm.entity == "Users") {
                Report.emailReport(
                    {
                        entityName: vm.entity,
                    },
                    function (response) {
                        vm.showMessageError = false;
                        vm.showMessage = true;
                        vm.trackingId = response.id
                        $timeout(function () {
                            $state.go('entity-dump');
                        }, 2000);  
                        myservice.setValue("true");
                    },
                    function (e) {
                        vm.errorMessage = e.data.message;
                        vm.showMessage = false;
                        vm.showMessageError = true;
                        $timeout(function () {
                            vm.showMessageError = false;
                        }, 4000);
                    }
                );
            } else {
                alert("Please select valid entity!");
            }
        };
    }
})();
