(function () {
	'use strict';

	angular.module('trakeyeApp').controller('CaseAgingReportController', CaseAgingReportController);
	angular.module('trakeyeApp').filter('trustFilter', ['$sce', function ($sce) {
		return function (text) {
			return $sce.trustAsHtml(text);
		};
	}]);

	CaseAgingReportController.$inject = ['$scope', '$sce', '$state', "Report", '$rootScope', '$timeout', '$window', '$filter', '$interval', 'Principal'];

	function CaseAgingReportController($scope, $sce, $state, Report, $rootScope, $timeout, $window, $filter, $interval, Principal) {
		var vm = this;
		vm.geofence = [];
		$scope.reporthide = false;
		// vm.reportUser = $rootScope.userName;
		vm.openCalendar = openCalendar;
		vm.geofence = loadPage();
		var dateFormat = 'dd EEE HH:mm';
		var date = "dd EEE";
		vm.fromTime = 0;
		vm.toTime = 0;
		vm.today = today();
		vm.datePickerOpenStatus = {};
		vm.reportGenerated = false;
		vm.reportGeneratedFileName = '';
		vm.data;
		vm.tableShow = false
		vm.dateRange;
		function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;
		}

		$(".select2").select2();

		function today() {
			var today = new Date();
			vm.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
			vm.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
			vm.fromDate.setHours(0, 0, 0, 0);
			vm.fromTime = vm.fromDate.getTime();
			vm.toDate.setHours(23, 59, 59, 999);
			vm.toTime = vm.toDate.getTime();
		}

		vm.calculateToDate = function calculateToDate() {
			vm.toDate.setHours(23, 59, 59, 999);
			vm.toTime = vm.toDate.getTime();
		};

		vm.calculateFromDate = function calculateFromDate() {
			vm.fromDate.setHours(0, 0, 0, 0);
			vm.fromTime = vm.fromDate.getTime();
		};

		function loadPage() {
			Report.getGeofences('', function (response) {
				vm.geofence = response;

			});
		}

		$scope.loader = false;

		$scope.getPdf = function () {
			$scope.loader = true;
			html2canvas(reportpdf, {
				onrendered: function (canvas) {
					var img = canvas.toDataURL("image/png");
					var pdf = new jsPDF('landscape', 'mm', 'a2');
					pdf.addImage(img, 'JPEG', 0, 0);
					pdf.save($scope.pdftype);
					$scope.loader = false;
				}
			});
			$interval(function () { }, 1000);
		};

		function select2() {
			$(".select2").select2();
		}

		$scope.report = function () {

			Report.getCasesAgingReport({
				reportType: "GEOFENCE",
				ageType: vm.dateRange,
				fromDateTime: vm.fromTime,
				toDateTime: vm.toTime,
				geofenceId: vm.reportGeofence
			}, function (response) {
				vm.data = response;
				clearChart()
				initBarChart(vm.data);
			});
			vm.reportGenerated = true;
		};

		$scope.downloadCaseAgingReport = function () {
			Report.getCasesAgingReport({
				ageType: vm.dateRange,
				fromDateTime: vm.fromTime,
				toDateTime: vm.toTime,
				geofenceId: vm.reportGeofence
			}, function (response) {
				var csvContent = "data:text/csv;charset=utf-8,";
				var headers = Object.keys(response[0]);
				csvContent += headers.join(",") + "\n";
				response.forEach(function (row) {
					var values = headers.map(function (header) {
						return row[header];
					});
					csvContent += values.join(",") + "\n";
				});
				var encodedUri = encodeURI(csvContent);
				var link = document.createElement("a");
				link.setAttribute("href", encodedUri);
				link.setAttribute("download", "case_aging_report.csv");
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			})
		};

		function clearChart() {
			d3.select("#virticalBarchart").selectAll("*").remove();
		}

		function initBarChart(data) {

			var colorScale = d3.scale.ordinal()
				.domain(["HIGH", "MEDIUM", "CRITICAL", "LOW"])
				.range(["#fd7a24", "#00a658", "red", "#294985"]);
			var groupedData = d3.nest()
				.key(function (d) {
					return d.ageType + "-" + d.priority;
				})
				.rollup(function (v) {
					return v.length;
				})
				.entries(data);
			var dataArray = groupedData.map(function (d) {
				var split = d.key.split("-");
				return {
					ageType: split[0],
					priority: split[1],
					count: d.values
				};
			});
			dataArray.sort(function (a, b) {
				return parseInt(a.ageType) - parseInt(b.ageType);
			});

			var margin = {
				top: 20,
				right: 30,
				bottom: 80,
				left: 50
			};
			var width = 1000 - margin.left - margin.right;
			var height = 450 - margin.top - margin.bottom;

			var tooltip1 = d3.select("body").append("div")
				.attr("class", "tooltip1")
				.style("opacity", 0);

			var svg = d3.select("#virticalBarchart")
				.append("svg")
				.attr("width", width + margin.left + margin.right)
				.attr("height", height + margin.top + margin.bottom)
				.append("g")
				.attr("transform", "translate(" + margin.left + "," + margin.top + ")");

			var x = d3.scale.ordinal()
				.domain(dataArray.map(function (d) { return d.ageType + "-" + d.priority; }))
				.rangeRoundBands([0, width], 0.6);

			var y = d3.scale.linear()
				.domain([0, d3.max(dataArray, function (d) { return d.count; })])
				.range([height, 0]);

			svg.selectAll(".bar2")
				.data(dataArray)
				.enter().append("rect")
				.attr("class", "bar2")
				.attr("fill", function (d) {
					return colorScale(d.priority.toUpperCase());
				})
				.attr("x", function (d) {
					return x(d.ageType + "-" + d.priority);
				})
				.attr("width", x.rangeBand())
				.attr("y", function (d) {
					return y(d.count);
				})
				.attr("height", function (d) {
					return height - y(d.count);
				}).on("mouseover", function (d) {
					tooltip1.transition()
						.duration(100)
						.style("opacity", .9);
					tooltip1.html("Age: " + d.ageType + "<br/>Priority: " + d.priority + "<br/>Case: " + d.count)
						.style("left", (d3.event.pageX) + "px")
						.style("top", (d3.event.pageY - 28) + "px");
				})
				.on("mouseout", function (d) {
					tooltip1.transition()
						.duration(300)
						.style("opacity", 0);
				});;

			svg.selectAll(".bar-text")
				.data(dataArray)
				.enter().append("text")
				.attr("class", "bar-text")
				.attr("x", function (d) { return x(d.ageType + "-" + d.priority) + x.rangeBand() / 2; })
				.attr("y", function (d) { return y(d.count) - 5; })
				.attr("text-anchor", "middle")
				.text(function (d) { return d.count; });

			svg.append("g")
				.attr("class", "x axis1")
				.attr("transform", "translate(0," + height + ")")
				.call(d3.svg.axis().scale(x).orient("bottom").tickFormat(function (d) {
					return d.split("-")[0];
				}))
				.selectAll("text")
				.style("text-anchor", "end")
				.attr("dx", "-.9em")
				.attr("dy", "1.0em")
				.attr("font-size", "10px")
				.attr("transform", "rotate(-45)");

			// Add Y axis
			svg.append("g")
				.attr("class", "y axis1")
				.call(d3.svg.axis().scale(y).orient("left").tickFormat(d3.format("d")));

			svg.append("text")
				.attr("transform", "rotate(-90)")
				.attr("y", 0 - margin.left)
				.attr("x", 0 - (height / 2))
				.attr("dy", "1em")
				.style("text-anchor", "middle")
				.attr("font-size", "17px")
				.text("CASES");

			// Legend
			var legend = svg.selectAll(".legend")
				.data(colorScale.domain())
				.enter().append("g")
				.attr("class", "legend")
				.attr("transform", function (d, i) {
					return "translate(0," + i * 20 + ")";
				});

			legend.append("rect")
				.attr("x", width - 18)
				.attr("width", 18)
				.attr("height", 18)
				.style("fill", colorScale);

			legend.append("text")
				.attr("x", width - 24)
				.attr("y", 9)
				.attr("dy", ".35em")
				.style("text-anchor", "end")
				.text(function (d) {
					return d;
				});
		}



	}
})();
