(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("UserManagementController", UserManagementController);

    UserManagementController.$inject = [
        "$rootScope",
        "$scope",
        "$ngConfirm",
        "$filter",
        "DEFAULT_LATITUDE",
        "DEFAULT_LONGITUDE",
        "Principal",
        "User",
        "ReportingUsers",
        "ParseLinks",
        "AlertService",
        "$state",
        "pagingParams",
        "paginationConstants",
        "JhiLanguageService",
        "UserValueSearch",
        "UsersStatusService",
        "$stateParams",
        "UserStatusSearch",
        "UsersHierarchy",
        "UserAccount",
        "GeofenceMasterMiniIDs",
        "UserGeofences",
        "AssetGroupES",
        "AssetByID",
        "NearbyUser",
        "MasterGeofences",
        "UserValueGeofenceSearch",
        "UsersByGeo",
        "DownloadUsers",
    ];

    function UserManagementController(
        $rootScope,
        $scope,
        $ngConfirm,
        $filter,
        DEFAULT_LATITUDE,
        DEFAULT_LONGITUDE,
        Principal,
        User,
        ReportingUsers,
        ParseLinks,
        AlertService,
        $state,
        pagingParams,
        paginationConstants,
        JhiLanguageService,
        UserValueSearch,
        UsersStatusService,
        $stateParams,
        UserStatusSearch,
        UsersHierarchy,
        UserAccount,
        GeofenceMasterMiniIDs,
        UserGeofences,
        AssetGroupES,
        AssetByID,
        NearbyUser,
        MasterGeofences,
        UserValueGeofenceSearch,
        UsersByGeo,
        DownloadUsers
    ) {
        var vm = this;
        $scope.search = pagingParams.search;
        vm.authorities = ["ROLE_USER", "ROLE_USER_ADMIN", "ROLE_VENDOR_ADMIN"];
        vm.currentAccount = null;
        vm.languages = null;
        vm.setActive = setActive;
        vm.loadAll = loadAll;
        vm.users = [];
        vm.usersList = [];
        vm.page = 1;
        vm.totalItems = null;
        var userMarkers = [];
        vm.clear = clear;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.downloadImage = downloadImage;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        vm.getLocalhour = getLocalhour;
        vm.filterSearch = filterSearch;
        vm.usersTable = usersTable;
        vm.selectedListGeofenceId = parseInt(pagingParams.listgeofenceid);
        vm.saveListFilter = saveListFilter;
        vm.filterAssetsOnListView = filterAssetsOnListView;
        vm.closeListFilterModal = closeListFilterModal;
        vm.downloadUserManagementUserList = downloadUserManagementUserList;
        vm.downloadUsers = downloadUsers;
        vm.filterAssetsOnUserManagementMapView = filterAssetsOnUserManagementMapView;
        vm.closeUserManagementMapFilterModal = closeUserManagementMapFilterModal;
        vm.saveUserManagementGeofenceFilter = saveUserManagementGeofenceFilter;
        vm.masterGeofences = [];
        // vm.loadAll();
        $scope.tab = 1;
        var svgUH = null;
        var svgUHwidth = null;
        var svgUHheigth = null;

        var activeInfoWindow = [];
        var prevSelectedCircle = null;
        vm.columnList = pagingParams.columnList;

        if(vm.columnList == undefined){
            vm.columnList = [{name:"login", ticked:true}, 
            {name:"email", ticked:true}, 
            {name:"phone", ticked:true}, 
            {name:"applicationVersion", ticked:true}, 
            {name:"gpsimei", ticked:true}];
        }
        

        vm.selectedColumnList = pagingParams.columnNames;

        var noDataText = null;
        var markers = [];
        var polylines = [];
        vm.userGeofences = [];
        var openedInfoWindow = null;

        var locations = {};
        var latlng;

        $scope.isSet = function (tabNum) {
            return $scope.tab === tabNum;
        };

        function myOption(zoom, center) {
            var myOptions;
            return (myOptions = {
                zoom: zoom,
                center: center,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: [
                    {
                        featureType: "poi",
                        elementType: "labels.icon",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    },
                    {
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    } /* 
                    {
                        elementType: "geometry.fill",
                        stylers: [
                            {
                                color: "#EFEFEF",
                            },
                        ],
                    }, */,
                    {
                        featureType: "transit",
                        elementType: "labels.icon",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    },
                    {
                        featureType: "road",
                        elementType: "labels.icon",
                        stylers: [{ visibility: "off" }],
                    },
                    {
                        featureType: "landscape",
                        stylers: [
                            {
                                color: "#efefef",
                            },
                        ],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#ffffff",
                            },
                        ],
                    }
                ],
            });
        }

        function geoLocation(map) {
            var infoWindow = new google.maps.InfoWindow();
            var locationButton = document.createElement("button");
            locationButton.setAttribute("title", "Locate Me");
            locationButton.classList.add("custom-map-control-button");
            var geoIcon = document.createElement("i");
            geoIcon.classList.add("fa");
            geoIcon.classList.add("fa-crosshairs");
            geoIcon.classList.add("geo");
            locationButton.appendChild(geoIcon);
            map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
                locationButton
            );

            locationButton.addEventListener("click", function () {
                // Try HTML5 geolocation.
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            var pos = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            };
                            infoWindow.setPosition(pos);
                            infoWindow.setContent("Location found.");
                            infoWindow.open(map);
                            map.setCenter(pos);
                            map.setZoom(18);
                        },
                        function () {
                            handleLocationError(
                                true,
                                infoWindow,
                                map.getCenter()
                            );
                        },
                        {
                            enableHighAccuracy: true,
                            timeout: 5000,
                            maximumAge: 0,
                        }
                    );
                } else {
                    // Browser doesn't support Geolocation
                    handleLocationError(false, infoWindow, map.getCenter());
                }
            });

            function handleLocationError(
                browserHasGeolocation,
                infoWindow,
                pos
            ) {
                infoWindow.setPosition(pos);
                infoWindow.setContent(
                    browserHasGeolocation
                        ? "Error: The Geolocation service failed."
                        : "Error: Your browser doesn't support geolocation."
                );
                infoWindow.open(map);
            }
        }

        $scope.geofencelabel = {
            list : {
                search          : "Search",
                nothingSelected : "City"      
            },
            map : {
                    search          : "Search",
                    nothingSelected : "Geofence"  
            }
        }

        $scope.setTab = function (newTab) {
            $scope.tab = newTab;

            if ($scope.tab == 1) {
                filterAssetsOnListView()
                /* vm.masterGeofences = [];
                vm.masterGeofences.push({id:0,name:"All"});
                if(isNaN(vm.selectedListGeofenceId)){
                    vm.selectedListGeofenceId = 0;
                }
                
                MasterGeofences.getAll(function (response) {
                    response.forEach(function(geofence){
                        vm.masterGeofences.push(geofence);
                    });
                }); */
                filterSearch();
            } else if ($scope.tab == 2) {
                UsersHierarchy.getUsersHierarchy(null, function (response) {
                    u_data = response;
                    orgChart.initTree({
                        id: "#body",
                        data: response,
                        modus: "diagonal",
                        loadFunc: loadChilds,
                    });
                });
            } else {
                filterAssetsOnUserManagementMapView()
                vm.mapAssets = [];
                var latlngs = new google.maps.LatLng(
                    DEFAULT_LATITUDE,
                    DEFAULT_LONGITUDE
                );
                /* var myOptions = {
                    zoom: 17,
                    center: latlngs,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                }; */
                var map = new google.maps.Map(
                    document.getElementById("map_canvas"),
                    myOption(17, latlngs)
                );
                geoLocation(map);
                //getGroupUserManagementAssetsFromES(vm.selectedGeofenceId, null, vm.mapAssets);
            }
        };

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });
        Principal.identity().then(function (account) {
            vm.currentAccount = account;
        });

        if ($scope.tab == 1) {
            UserAccount.get(function (data) {
                var isAdmin = false;
                data.authorities.forEach(function (val) {
                    if (val == "ROLE_USER_ADMIN") {
                        isAdmin = true;
                    } else if (val == "ROLE_HOD") {
                        isAdmin = true;
                    }
                });
                if (isAdmin) {
                    vm.adminFilter = true;
                } else {
                    vm.adminFilter = false;
                }
            });
            filterAssetsOnListView()
        }

        function filterAssetsOnListView() {
            var tempGeofenceId = $state.params.listgeofenceid
            vm.masterGeofences = [];
            vm.masterGeofences.push({ id: 0, name: "All" });
            if (isNaN(vm.selectedListGeofenceId)) {
                vm.selectedListGeofenceId = 0;
            }
            MasterGeofences.getAll(function (response) {
                response.forEach(function (geofence) {
                    geofence.ticked = (tempGeofenceId == geofence.id) ? true : false
                    vm.masterGeofences.push(geofence);
                });
            });
            //$("#listFilterModal").show();
            /* noDataText = document.getElementById("noData");
            noDataText.style.display = "none"; */
            vm.PrevselectedListGeofenceId = vm.selectedListGeofenceId;
        }

        function closeListFilterModal() {
            $("#listFilterModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#listFilterModal").hide();
            vm.selectedListGeofenceId = vm.PrevselectedListGeofenceId;
        }

        function saveListFilter() {
            vm.PrevselectedListGeofenceId = vm.selectedListGeofenceId;
            $("#listFilterModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#listFilterModal").hide();
            filterSearch();
        }

        function usersTable(actualElement, successFunction) {
            UsersHierarchy.getUsersHierarchy(
                actualElement.id,
                function (response) {
                    vm.usersData = response.children;
                }
            );
        }

        function getLocalhour(hour) {
            var d = new Date();
            d.setUTCHours(hour);
            return d.getHours();
        }

        function setActive(user, isActivated) {
            user.activated = isActivated;
            User.update(user, function () {
                vm.loadAll();
                vm.clear();
            });
        }

        // ReportingUsers.getAll(function(response) {
        //     vm.usersReporting = response;
        // });

        if (
            $scope.search === null ||
            $scope.search === "" ||
            angular.isUndefined($scope.search)
        ) {
            loadAll();
        } else {
            filterSearch();
        }

        $scope.geofenceListView = function(){
            if($scope.tab == 1){
                vm.selectedListGeofenceId = vm.selectedGeofenceNearby[0].id
                saveListFilter()
            }else if($scope.tab == 3){
                vm.selectedGeofenceId = vm.selectedGeofenceNearby[0].id
                saveUserManagementGeofenceFilter()
            }
            
        }
        function filterSearch() {
            if (
                $scope.search != null &&
                $scope.search != "" &&
                !angular.isUndefined($scope.search)
            ) {
                if ($stateParams.status == undefined) {
                    if (
                        isNaN(vm.selectedListGeofenceId) ||
                        vm.selectedListGeofenceId == 0
                    ) {
                        UserValueSearch.query(
                            {
                                search: $scope.search,
                                page: pagingParams.page - 1,
                                size: vm.itemsPerPage,
                                sort: sort(),
                            },
                            onSuccess,
                            onError
                        );
                    } else {
                        var columnList = [];
                        vm.selectedColumnList.forEach(function(value){
                            columnList.push(value.name);
                        });

                        UserValueGeofenceSearch.query(
                            {
                                search: $scope.search,
                                geofenceId: vm.selectedListGeofenceId,
                                page: pagingParams.page - 1,
                                size: vm.itemsPerPage,
                                sort: sort(),
                                columnNames: columnList
                            },
                            onSuccess,
                            onError
                        );
                    }
                } else {
                    UserStatusSearch.query(
                        {
                            status: $stateParams.status,
                            searchtext: $scope.search,
                            page: pagingParams.page - 1,
                            size: vm.itemsPerPage,
                            sort: sort(),
                        },
                        onSuccess,
                        onError
                    );
                }
            } else {
                loadAll();
            }
        }

        function loadAll() {

            if ($stateParams.status == undefined) {
                if (
                    isNaN(vm.selectedListGeofenceId) ||
                    vm.selectedListGeofenceId == 0
                ) {
                    User.query(
                        {
                            page: pagingParams.page - 1,
                            size: vm.itemsPerPage,
                            sort: sort(),
                        },
                        onSuccess,
                        onError
                    );
                } else {
                    UsersByGeo.query(
                        {
                            geofenceId: vm.selectedListGeofenceId,
                            page: pagingParams.page - 1,
                            size: vm.itemsPerPage,
                            sort: sort(),
                        },
                        onSuccess,
                        onError
                    );
                }
            } else {
                UsersStatusService.query(
                    {
                        status: $stateParams.status,
                        geofenceId : $stateParams.geofenceId,
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort(),
                    },
                    onSuccess,
                    onError
                );
            }
        }

        function onSuccess(data, headers) {
            // hide anonymous user from user management: it's a required user
            // for Spring Security
            for (var i in data) {
                if (data[i]["login"] === "anonymoususer") {
                    data.splice(i, 1);
                }
            }
            vm.links = ParseLinks.parse(headers("link"));
            vm.totalItems = headers("X-Total-Count");
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.users = data;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function clear() {
            vm.user = {
                id: null,
                login: null,
                firstName: null,
                lastName: null,
                email: null,
                phone: null,
                imsi: null,
                activated: null,
                langKey: null,
                createdBy: null,
                createdDate: null,
                lastModifiedBy: null,
                lastModifiedDate: null,
                resetDate: null,
                resetKey: null,
                authorities: null,
            };
        }

        function sort() {
            var result = [vm.predicate + "," + (vm.reverse ? "asc" : "desc")];
            if (vm.predicate !== "id") {
                // result.push('id');
            }
            return result;
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                search: $scope.search,
                status: pagingParams.status,
                geofenceId : pagingParams.geofenceId,
                listgeofenceid: vm.selectedListGeofenceId,
                columnNames: vm.selectedColumnList,
                columnList: vm.columnList
            });
        }

        function filterAssetsOnUserManagementMapView() {
            vm.userGeofences = [];
            if (vm.adminFilter) {
                GeofenceMasterMiniIDs.getAll(function (response) {
                    response.forEach(function (geofence) {
                        geofence.label = geofence.name.substring(0, 15) + "..";
                        vm.userGeofences.push(geofence);
                        if (vm.selectedGeofenceId == null) {
                            vm.selectedGeofenceId = geofence.id;
                        }
                    });
                    // filterSearch();
                });
            } else {
                UserGeofences.getAll(function (response) {
                    response.forEach(function (geofence) {
                        vm.userGeofences.push(geofence);
                        if (vm.selectedGeofenceId == null) {
                            vm.selectedGeofenceId = geofence.id;
                        }
                    });
                    //filterSearch();
                });
            }
            //$("#mapFilterUserManagementModal").show();
            /* noDataText = document.getElementById("noData");
            noDataText.style.display = "none"; */
            //vm.prevSelectedGeofenceId = vm.selectedGeofenceId;
        }

        function closeUserManagementMapFilterModal() {
            $("#mapFilterUserManagementModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#mapFilterUserManagementModal").hide();
            vm.selectedGeofenceId = vm.prevSelectedGeofenceId;
        }

        function saveUserManagementGeofenceFilter() {
            vm.mapAssets = [];
            // var myOptions = {
            //     zoom: 17,
            //     center: latlngs,
            //     mapTypeId: google.maps.MapTypeId.ROADMAP
            // };
            // var map = new google.maps.Map(document.getElementById("map_canvas"), myOptions);
            getGroupUserManagementAssetsFromES(
                vm.selectedGeofenceId,
                null,
                vm.mapAssets
            );
            //vm.prevSelectedGeofenceId = vm.selectedGeofenceId;
/* 
            $("#mapFilterUserManagementModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#mapFilterUserManagementModal").hide(); */
        }

        function getGroupUserManagementAssetsFromES(
            geofenceIdVal,
            lastAssetId,
            mapAssetLists
        ) {
            AssetGroupES.get(geofenceIdVal, lastAssetId, function (data) {
                vm.mapAssets = mapAssetLists;
                data.assets.forEach(function (asset) {
                    var tmpAsset = {};

                    tmpAsset.assetCoordinates = asset.assetCoordinates;
                    tmpAsset.assetTypeName = asset.assetTypeName;
                    tmpAsset.assetTypeColor = asset.assetColor;
                    tmpAsset.assetTypeImagePath = asset.assetTypeImagePath;
                    tmpAsset.assetTypeLayout = asset.assetTypeLayout;
                    tmpAsset.id = asset.id;
                    tmpAsset.name = asset.name;

                    vm.mapAssets.push(tmpAsset);
                });
                drawLatestAssetsOnMap(vm.mapAssets);
                if (data.lastId != null) {
                    getGroupUserManagementAssetsFromES(
                        geofenceIdVal,
                        data.lastId,
                        vm.mapAssets
                    );
                } else {
                    if (vm.mapAssets.length > 0) {
                        var assetSelected = vm.mapAssets[0];
                        AssetByID.get(assetSelected.id, function (assetVal) {
                            var assetLatLng = {
                                lat:
                                    assetSelected.assetCoordinates[
                                        assetSelected.assetCoordinates.length -
                                            1
                                    ].latitude,
                                lng:
                                    assetSelected.assetCoordinates[
                                        assetSelected.assetCoordinates.length -
                                            1
                                    ].longitude,
                            };
                            locations.map.setCenter(assetLatLng);
                        });
                    }
                }
                // filterGeofenceSearchMap();
            });
        }

        function drawLatestAssetsOnMap(response) {
            if (response) {
                initializeGeofenceAssetMap(response, function (latlang) {
                    locations.latlng = latlang;

                    setupAssetMap(function () {
                        $.each(response, function (index, value) {
                            if (value.assetTypeLayout == null) {
                                value.assetTypeName = value.assetType.name;
                                value.assetTypeLayout = value.assetType.layout;
                                value.assetTypeImagePath =
                                    value.assetType.imagePath;
                            }
                            if (
                                value.assetTypeLayout != null &&
                                value.assetTypeLayout == "FIXED"
                            ) {
                                if (value.assetCoordinates.length > 1) {
                                    drawPolyline(value);
                                    var value1 = value;
                                    value1.assetCoordinates = [
                                        value.assetCoordinates[
                                            value.assetCoordinates.length - 1
                                        ],
                                    ];

                                    drawFixedGeofenceAsset(value1, false);
                                } else {
                                    drawFixedGeofenceAsset(value, false);
                                }
                            }
                            if (
                                value.assetTypeLayout != null &&
                                value.assetTypeLayout == "SPREAD"
                            ) {
                                drawPolyline(value, locations.map);
                            }
                        });
                    });
                });
            }
        }

        function initializeGeofenceAssetMap(latlangs, callback) {
            if (latlangs && latlangs.length > 0) {
                var latVal = null;
                var lngVal = null;
                latlangs.forEach(function (value) {
                    if (latVal == null && lngVal == null) {
                        if (value.assetCoordinates.length > 0) {
                            latVal =
                                value.assetCoordinates[
                                    value.assetCoordinates.length - 1
                                ].latitude;
                            lngVal =
                                value.assetCoordinates[
                                    value.assetCoordinates.length - 1
                                ].longitude;
                        }
                    }
                });
                if (latVal != null && lngVal != null) {
                    latlng = new google.maps.LatLng(latVal, lngVal);
                    callback(latlng);
                }
            } else {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            latlng = new google.maps.LatLng(
                                position.coords.latitude,
                                position.coords.longitude
                            );
                            callback(latlng);
                        },
                        function () {
                            // callback(new google.maps.LatLng("12.9716", "77.5946"));
                            callback(
                                new google.maps.LatLng(
                                    DEFAULT_LATITUDE,
                                    DEFAULT_LONGITUDE
                                )
                            );
                        }
                    );
                } else {
                    callback(
                        new google.maps.LatLng(
                            DEFAULT_LATITUDE,
                            DEFAULT_LONGITUDE
                        )
                    );
                }
            }
        }

        $("#searchUsersOnMap").change(function () {
            var userSelected = JSON.parse($("#searchUsersOnMap").val());
            var userLatLng = {
                lat: userSelected.latitude,
                lng: userSelected.longitude,
            };
            locations.map.setCenter(userLatLng);
        });

        function drawLatestlocations(response) {
            vm.usersList = response;
            for (var i = 0; i < userMarkers.length; i++) {
                userMarkers[i].setMap(null);
            }
            userMarkers = [];
            var markers = [
                {
                    Lat: "0.0",
                    Lng: "0.0",
                    name: "default",
                },
            ];

            $.each(response, function (index, value) {
                var infoWindow2 = new google.maps.InfoWindow();
                var imgbg = document.createElement("img");
                imgbg.className = "marker_back_image";

                if (value.reportingStatus == true) {
                    if (value.status == "ACTIVE") {
                        imgbg.src = "content/images/greenmarker.png";
                    } else if (value.status == "INACTIVE") {
                        imgbg.src = "content/images/redmarker.png";
                    } else if (value.status == "IDLE") {
                        imgbg.src = "content/images/yellowmarker.png";
                    } else if (value.status == "Off duty user") {
                        imgbg.src = "content/images/marker_offduty1.png";
                    }
                } else {
                    imgbg.src = "content/images/marker_active_case.png";
                }

                if (value.address == "SOS") {
                    imgbg.src = "content/images/alert.png";
                }

                if ($scope.trCases != undefined && $scope.trCases.length > 0) {
                    $.each($scope.trCases, function (index, value) {
                        var infoWindow = new google.maps.InfoWindow();
                        var startMarker = new google.maps.Marker({
                            map: locations.map,
                            position: new google.maps.LatLng(
                                value.pinLat,
                                value.pinLong
                            ),
                            title: value.description,
                        });
                        var infoWindow2 = new google.maps.InfoWindow();
                        if (value.priority == "LOW") {
                            startMarker.setIcon(
                                "content/images/Pointers-L4.png"
                            );
                        } else if (value.priority == "MEDIUM") {
                            startMarker.setIcon(
                                "content/images/Pointers-M4.png"
                            );
                        } else if (value.priority == "HIGH") {
                            startMarker.setIcon(
                                "content/images/Pointers-H4.png"
                            );
                        } else if (value.priority == "CRITICAL") {
                            startMarker.setIcon(
                                "content/images/Pointer-C4.png"
                            );
                        }
                        var span =
                            '<a userId="' +
                            value.geofenceName +
                            '" class="userpath" >Load travelled path</a>';
                        startMarker.content =
                            '<div class="infoWindowContent"><b>Case Id : </b>' +
                            value.id +
                            "<br> <b>Reported By :</b> " +
                            value.reportedByUser +
                            "<br> <b> Assigned To :</b> " +
                            value.assignedToUser +
                            "<br> <b>Address :</b> " +
                            value.address +
                            "</div>";

                        $compile(startMarker.content)($scope);

                        google.maps.event.addListener(
                            startMarker,
                            "mouseover",
                            function () {
                                infoWindow.setContent(
                                    '<div class="infoWindowhead">' +
                                        startMarker.title +
                                        "</div>" +
                                        startMarker.content
                                );
                                infoWindow.open(locations.map, startMarker);
                            }
                        );
                        // on mouseout
                        google.maps.event.addListener(
                            startMarker,
                            "mouseout",
                            function () {
                                infoWindow.close();
                            }
                        );

                        var activeInfoWindow;
                        infoWindow2.setContent(
                            '<div class="infoWindowhead">' +
                                startMarker.title +
                                "</div>" +
                                startMarker.content
                        );
                        userMarkers.push(startMarker);
                    });
                }

                function customMarker() {
                    this.latlng = new google.maps.LatLng(
                        value.latitude,
                        value.longitude
                    );
                    this.setMap(locations.map);
                    this.title = value.login;
                    this.icon = imgbg;
                }
                var userContent = document.createElement("div"),
                    button;

                if (value.userImage != null) {
                    userContent.innerHTML =
                        '<div class="infoWindowhead">' +
                        value.login +
                        '</div><div class="infoWindowContent"><div class="rows"><div class="col-md-4"><img src="data:image/png;base64,' +
                        value.userImage +
                        '" style="width: 50px; height: 60px;"></div><div class="col-md-8"><p>' +
                        value.address +
                        "</p><p><b>Phone</b> : " +
                        value.phone;
                    button = userContent.appendChild(
                        document.createElement("input")
                    );
                    button.type = "button";
                    button.value = "Load travelled path";
                    button.className = "button";

                    google.maps.event.addDomListener(
                        button,
                        "click",
                        function () {
                            markerUserName = value.login;
                            userPath(value.userid);
                        }
                    );
                } else {
                    userContent.innerHTML =
                        '<div class="infoWindowhead"><span style="margin-left:10px;"><b>' +
                        value.login +
                        '</b></span></div><div style="width: 300px; height: 120px;" class="infoWindowContent"><div class="rows"><div class="col-md-7"><p>' +
                        value.address +
                        "</p><p><b>Phone</b> : " +
                        value.phone +
                        "</p><b>Latitude</b> : " +
                        value.latitude +
                        "</p> <p> <b>Longitude</b> : " +
                        value.longitude +
                        '</p> </div></div><div class="col-md-5"> <img src="content/images/userimg.gif" style="width: 80px; height: 90px; margin-top:26px;"></div></div>';

                    button = userContent.appendChild(
                        document.createElement("input")
                    );
                    button.type = "button";
                    button.value = "Load travelled path";
                    button.className = "button"; // set the CSS class

                    google.maps.event.addDomListener(
                        button,
                        "click",
                        function () {
                            markerUserName = value.login;
                            userPath(value.userid);
                        }
                    );
                }

                function userPath(id) {
                    var ele = document.getElementById("userPathModal");
                    $(ele).modal("show");
                    vm.drawUserPath(id);
                    var cleratodatefromdate = vm.today();
                    // cleratodatefromdate.remove();
                }
                var infowindowPopup = new google.maps.InfoWindow({
                    content: userContent,
                    pixelOffset: new google.maps.Size(0, -50),
                });

                customMarker.prototype = new google.maps.OverlayView();

                customMarker.prototype.draw = function () {
                    var self = this;
                    var div = this.div;

                    if (!div) {
                        div = this.div = document.createElement("div");
                        div.id = "marker";
                        div.style.width = "100px";
                        div.style.height = "100px";

                        var div_pointer = document.createElement("div");
                        div_pointer.className = "triangle";

                        var image_container = document.createElement("div");
                        image_container.className = "image_container";

                        var img = document.createElement("img");
                        img.className = "marker_image";
                        var image2 = "data:image/png;base64,";
                        image2 += value.userImage;
                        if (value.userImage == undefined) {
                            image2 = "content/images/userimg.gif";
                        }

                        img.src = image2;

                        var name_container = document.createElement("div");
                        name_container.className = "name_container";

                        var exit = document.createElement("div");
                        exit.className = "exit";
                        exit.innerHTML =
                            '<img className="exit_image" style="width:30px; height:30px;" src="https://cdn0.iconfinder.com/data/icons/large-glossy-icons/512/Delete.png">' +
                            "</img>";
                        exit.style.display = "none";

                        div.appendChild(image_container);
                        image_container.appendChild(img);
                        image_container.appendChild(imgbg);

                        div.appendChild(exit);

                        name_container.onmouseover = function () {
                            name_container.style.opacity = "0.6";
                            div.style.zIndex = "1000";
                        };
                        name_container.onmouseout = function () {
                            name_container.style.opacity = "0";
                            div.style.zIndex = "100";
                        };

                        infoWindow2.setContent(
                            '<div class="infoWindowhead">' +
                                value.login +
                                "</div>" +
                                userContent
                        );
                        // if (activeInfoWindow != null)
                        //     activeInfoWindow.close();

                        google.maps.event.addDomListener(
                            imgbg,
                            "click",
                            function (event) {
                                infowindowPopup.setPosition(
                                    new google.maps.LatLng(
                                        value.latitude,
                                        value.longitude
                                    )
                                );
                                infowindowPopup.open(locations.map);

                                if (activeInfoWindow != null)
                                    activeInfoWindow.close();
                                activeInfoWindow = infowindowPopup;
                            }
                        );

                        google.maps.event.addDomListener(
                            imgbg,
                            "click",
                            function (event) {
                                google.maps.event.trigger(self, "click");

                                infowindowPopup.open(locations.map);
                            }
                        );

                        var panes = this.getPanes();

                        panes.overlayImage.appendChild(div);
                    }

                    var point = this.getProjection().fromLatLngToDivPixel(
                        this.latlng
                    );

                    if (point) {
                        div.style.left = point.x - 50 + "px";
                        div.style.top = point.y - 125 + "px";
                    }
                };

                customMarker.prototype.remove = function () {
                    if (this.div) {
                        this.div.parentNode.removeChild(this.div);
                        this.div = null;
                    }
                };

                customMarker.prototype.getPosition = function () {
                    return this.latlng;
                };
                markers.forEach(function (marker) {
                    // image =
                    // value.userImage;

                    var overlay = new customMarker();
                });
                // end
            });
        }

        function downloadImage() {
            var svgString = getSVGString(svgUH.node());
            svgString2Image(svgString, 2 * 2160, 2 * 2160, "png", save);

            function save(dataBlob, filesize) {
                var imageURL = URL.createObjectURL(dataBlob);

                var link = document.createElement("a");
                link.href = imageURL;
                link.download = "UserHierarchy.png";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }

        // Below are the functions that handle actual exporting:
        // getSVGString ( svgNode ) and svgString2Image( svgString, width, height, format, callback )
        function getSVGString(svgNode) {
            svgNode.setAttribute("xlink", "http://www.w3.org/1999/xlink");
            var cssStyleText = getCSSStyles(svgNode);
            appendCSS(cssStyleText, svgNode);

            var serializer = new XMLSerializer();
            var svgString = serializer.serializeToString(svgNode);
            svgString = svgString.replace(/(\w+)?:?xlink=/g, "xmlns:xlink="); // Fix root xlink without namespace
            svgString = svgString.replace(/NS\d+:href/g, "xlink:href"); // Safari NS namespace fix

            return svgString;

            function getCSSStyles(parentElement) {
                var selectorTextArr = [];

                // Add Parent element Id and Classes to the list
                selectorTextArr.push("#" + parentElement.id);
                for (var c = 0; c < parentElement.classList.length; c++)
                    if (
                        !contains(
                            "." + parentElement.classList[c],
                            selectorTextArr
                        )
                    )
                        selectorTextArr.push("." + parentElement.classList[c]);

                // Add Children element Ids and Classes to the list
                var nodes = parentElement.getElementsByTagName("*");
                for (var i = 0; i < nodes.length; i++) {
                    var id = nodes[i].id;
                    if (!contains("#" + id, selectorTextArr))
                        selectorTextArr.push("#" + id);

                    var classes = nodes[i].classList;
                    for (var c = 0; c < classes.length; c++)
                        if (!contains("." + classes[c], selectorTextArr))
                            selectorTextArr.push("." + classes[c]);
                }

                // Extract CSS Rules
                var extractedCSSText = "";
                for (var i = 0; i < document.styleSheets.length; i++) {
                    var s = document.styleSheets[i];

                    try {
                        if (!s.cssRules) continue;
                    } catch (e) {
                        if (e.name !== "SecurityError") throw e; // for Firefox
                        continue;
                    }

                    var cssRules = s.cssRules;
                    for (var r = 0; r < cssRules.length; r++) {
                        if (contains(cssRules[r].selectorText, selectorTextArr))
                            extractedCSSText += cssRules[r].cssText;
                    }
                }

                return extractedCSSText;

                function contains(str, arr) {
                    return arr.indexOf(str) === -1 ? false : true;
                }
            }

            function appendCSS(cssText, element) {
                var styleElement = document.createElement("style");
                styleElement.setAttribute("type", "text/css");
                styleElement.innerHTML = cssText;
                var refNode = element.hasChildNodes()
                    ? element.children[0]
                    : null;
                element.insertBefore(styleElement, refNode);
            }
        }

        function svgString2Image(svgString, width, height, format, callback) {
            var format = format ? format : "png";

            var imgsrc =
                "data:image/svg+xml;base64," +
                btoa(unescape(encodeURIComponent(svgString))); // Convert SVG string to data URL

            var canvas = document.createElement("canvas");
            var context = canvas.getContext("2d");

            canvas.width = width;
            canvas.height = height;

            var image = new Image();
            image.onload = function () {
                context.clearRect(0, 0, width, height);
                context.drawImage(image, 0, 0, width, height);

                canvas.toBlob(function (blob) {
                    var filesize = Math.round(blob.length / 1024) + " KB";
                    if (callback) callback(blob, filesize);
                });
            };

            image.src = imgsrc;
        }

        function onSuccessUsers(data, headers) {
            drawLatestlocations($filter("filter")(data, { login: "" }));
        }

        function setupAssetMap(callback) {
            if (locations.map == null) {
                /* var myOptions = {
                    zoom: 16,
                    center: locations.latlng,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                }; */

                locations.map = new google.maps.Map(
                    document.getElementById("map_canvas"),
                    myOption(16, locations.latlng)
                );
                geoLocation(locations.map);
                var address = new google.maps.Geocoder();

                google.maps.event.addListener(
                    locations.map,
                    "click",
                    function (event) {
                        address.geocode(
                            {
                                latLng: event.latLng,
                            },
                            function (results, status) {
                                if (status == google.maps.GeocoderStatus.OK) {
                                    if (results[0]) {
                                        $ngConfirm({
                                            title:
                                                "Are you sure you want to select this address?",
                                            content:
                                                "<strong>Address: </strong>" +
                                                results[0].formatted_address +
                                                "<br/>" +
                                                "<strong>Latitude: </strong>" +
                                                event.latLng.lat() +
                                                "</br>" +
                                                "<strong>Longitude: </strong>" +
                                                event.latLng.lng(),
                                            type: "blue",
                                            typeAnimated: true,
                                            buttons: {
                                                OK: {
                                                    text: "OK",
                                                    btnClass: "btn-primary",
                                                    action: function () {
                                                        var distance = parseInt(
                                                            document.getElementById(
                                                                "user_distance"
                                                            ).value
                                                        );

                                                        vm.filterData = true;
                                                        var clickLoc = {
                                                            lat: event.latLng.lat(),
                                                            lng: event.latLng.lng(),
                                                        };
                                                        var circle = new google.maps.Circle(
                                                            {
                                                                map:
                                                                    locations.map,
                                                                radius:
                                                                    distance +
                                                                    distance /
                                                                        2,
                                                                center: clickLoc,
                                                                fillColor:
                                                                    "#FD7A24",
                                                                strokeColor:
                                                                    "#FF0000",
                                                                strokeOpacity: 0.8,
                                                                strokeWeight: 1.0,
                                                            }
                                                        );

                                                        if (
                                                            prevSelectedCircle ==
                                                            null
                                                        ) {
                                                            prevSelectedCircle = circle;
                                                        } else {
                                                            prevSelectedCircle.setMap(
                                                                null
                                                            );
                                                            prevSelectedCircle = circle;
                                                        }
                                                        NearbyUser.query(
                                                            {
                                                                latitude: event.latLng.lat(),
                                                                longitude: event.latLng.lng(),
                                                                dis: distance,
                                                            },
                                                            onSuccessUsers
                                                        );
                                                    },
                                                },
                                                Cancel: {
                                                    text: "Cancel",
                                                    btnClass: "btn-danger",
                                                    action: function () {},
                                                },
                                            },
                                        });
                                    }
                                }
                            }
                        );
                    }
                );
                // google.maps.event.addListener(locations.map, 'zoom_changed', function () {
                //     vm.zoomLevel = locations.map.getZoom();
                //     if ((vm.prevZoomLevel == 17 && vm.zoomLevel == 18) || (vm.prevZoomLevel == 18 && vm.zoomLevel == 17)) {
                //         drawLatestAssetsOnMap(vm.mapAssets);
                //     }
                //     vm.prevZoomLevel = vm.zoomLevel;

                // });
            } else {
                // Loop through all the markers and polylines to remove

                for (var i = 0; i < markers.length; i++) {
                    markers[i].setMap(null);
                }

                for (i = 0; i < polylines.length; i++) {
                    polylines[i].setMap(null);
                }

                markers = [];
                polylines = [];
            }
            locations.overlay = new google.maps.OverlayView();
            locations.overlay.draw = function () {};
            locations.overlay.setMap($scope.map);
            locations.element = document.getElementById("map_canvas");
            callback();
        }

        function drawPolyline(spread, map) {
            var spreadAssetCoordinates = [];
            $.each(spread.assetCoordinates, function (index, value) {
                spreadAssetCoordinates.push({
                    lat: value.latitude,
                    lng: value.longitude,
                });
            });
            if (spreadAssetCoordinates.length > 1) {
                var midLatLng = {
                    lat:
                        spread.assetCoordinates[
                            spread.assetCoordinates.length - 1
                        ].latitude,
                    lng:
                        spread.assetCoordinates[
                            spread.assetCoordinates.length - 1
                        ].longitude,
                };

                var polylineColor = "#FFFFFF";
                var distanceFromPOP = "";
                var fiberCoreVal = "";
                var infowindow = new google.maps.InfoWindow();
                if (spread.assetTypeLayout == "SPREAD") {
                    polylineColor = spread.assetTypeColor;
                }
                var polylineWeight = 2.5;
                var strokeOpacityVal = 1.0;

                var polylinepath = new google.maps.Polyline({
                    path: spreadAssetCoordinates,
                    geodesic: true,
                    strokeColor: polylineColor,
                    strokeOpacity: strokeOpacityVal,
                    strokeWeight: polylineWeight,
                    fillColor: polylineColor,
                    fillOpacity: 0.3,
                    editable: false,
                });

                google.maps.event.addListener(
                    polylinepath,
                    "mouseout",
                    function (event) {
                        infowindow.close();
                    }
                );

                google.maps.event.addListener(
                    polylinepath,
                    "click",
                    function (event) {
                        if (openedInfoWindow != null) {
                            openedInfoWindow.close();
                        }
                        infowindow.setContent(
                            '<div class="infoWindowhead">' +
                                "<b>Asset Name : </b><br><br>" +
                                spread.name +
                                "</br></div>"
                        );

                        infowindow.mousemove;
                        infowindow.open(locations.map);
                        infowindow.setPosition(event.latLng);
                        openedInfoWindow = infowindow;
                    }
                );

                polylinepath.setMap(locations.map);
                polylines.push(polylinepath);
            }
        }

        function drawFixedGeofenceAsset(fixed, hasParent) {
            $.each(fixed.assetCoordinates, function (index, value1) {
                if (openedInfoWindow != null) {
                    openedInfoWindow.close();
                }

                var infowindow = new google.maps.InfoWindow();

                var iconUrl = "";
                var isFixedAsset = false;
                iconUrl = fixed.assetTypeImagePath;
                isFixedAsset = true;

                var iconSize = 8;
                if (vm.zoomLevel == 10) {
                    iconSize = 8;
                } else if (vm.zoomLevel == 11) {
                    iconSize = 16;
                }

                if (isFixedAsset) {
                    iconSize = 24;
                }

                var startMarker = new google.maps.Marker({
                    map: locations.map,
                    position: new google.maps.LatLng(
                        value1.latitude,
                        value1.longitude
                    ),
                    title: fixed.name,
                    icon: {
                        scaledSize: new google.maps.Size(iconSize, iconSize),
                        url: iconUrl,
                        anchor: new google.maps.Point(
                            iconSize / 2,
                            iconSize / 2
                        ),
                    },
                });
                google.maps.event.addListener(
                    startMarker,
                    "mouseout",
                    function (event) {
                        infowindow.close();
                    }
                );

                google.maps.event.addListener(
                    startMarker,
                    "click",
                    function (event) {
                        if (openedInfoWindow != null) {
                            openedInfoWindow.close();
                        }
                        infowindow.setContent(
                            '<div class="infoWindowhead">' +
                                "<b>Asset Name : </b><br><br>" +
                                fixed.name +
                                "</br></div>"
                        );

                        infowindow.mousemove;
                        infowindow.open(locations.map);
                        infowindow.setPosition(event.latLng);
                        openedInfoWindow = infowindow;
                    }
                );
                markers.push(startMarker);

                openedInfoWindow = infowindow;
            });
        }

        /* treeeeeeeeeeeeeeeeeeeeeeeeeeeee */
        var orgChart = (function () {
            var _margin = {
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                },
                _root = {},
                _nodes = [],
                _counter = 0,
                _svgroot = null,
                _svg = null,
                _tree = null,
                _diagonal = null,
                _lineFunction = null,
                _loadFunction = null,
                /* Configuration */
                _duration = 750,
                /* Duration of the animations */
                _rectW = 150,
                /* Width of the rectangle */
                _rectH = 50,
                /* Height of the rectangle */
                _rectSpacing = 20,
                /* Spacing between the rectangles */
                _fixedDepth = 80,
                /*
                 * Height of the line for child
                 * nodes
                 */
                _mode = "line",
                /*
                 * Choose the values "line" or
                 * "diagonal"
                 */
                _callerNode = null,
                _callerMode = 0,
                defLinearGradient = function (id, x1, y1, x2, y2, stopsdata) {
                    var gradient = _svgroot
                        .append("svg:defs")
                        .append("svg:linearGradient")
                        .attr("id", id)
                        .attr("x1", x1)
                        .attr("y1", y1)
                        .attr("x2", x2)
                        .attr("y2", y2)
                        .attr("spreadMethod", "pad");

                    $.each(stopsdata, function (index, value) {
                        gradient
                            .append("svg:stop")
                            .attr("offset", value.offset)
                            .attr("stop-color", value.color)
                            .attr("stop-opacity", value.opacity);
                    });
                },
                defBoxShadow = function (id) {
                    var filter = _svgroot
                        .append("svg:defs")
                        .append("svg:filter")
                        .attr("id", id)
                        .attr("height", "150%")
                        .attr("width", "150%");

                    filter
                        .append("svg:feOffset")
                        .attr("dx", "2")
                        .attr("dy", "2")
                        .attr("result", "offOut"); // how
                    // much
                    // to
                    // offset
                    filter
                        .append("svg:feGaussianBlur")
                        .attr("in", "offOut")
                        .attr("result", "blurOut")
                        .attr("stdDeviation", "2"); // stdDeviation
                    // is
                    // how
                    // much
                    // to
                    // blur
                    filter
                        .append("svg:feBlend")
                        .attr("in", "SourceGraphic")
                        .attr("in2", "blurOut")
                        .attr("mode", "normal");
                },
                collapse = function (d) {
                    if (d.children) {
                        d._children = d.children;
                        d._children.forEach(collapse);
                        d.children = null;
                    }
                },
                update = function (source) {
                    // Compute the new tree layout.
                    _nodes = _tree.nodes(_root).reverse();
                    var links = _tree.links(_nodes);

                    // Normalize for fixed-depth.
                    _nodes.forEach(function (d) {
                        d.y = d.depth * _fixedDepth;
                    });

                    // Update the nodes
                    var node = _svg
                        .selectAll("g.node")
                        .data(_nodes, function (d) {
                            return d.id || (d.id = ++_counter);
                        });

                    // Enter any new nodes at the parent's previous position.
                    var nodeEnter = node
                        .enter()
                        .append("g")
                        .attr("class", "node")
                        .attr("transform", function (d) {
                            return (
                                "translate(" + source.x0 + "," + source.y0 + ")"
                            );
                        })
                        .on("click", nodeclick);

                    nodeEnter
                        .append("rect")
                        .attr("width", _rectW)
                        .attr("height", _rectH)
                        .attr("fill", "#898989")
                        .attr("filter", "url(#boxShadow)");

                    nodeEnter
                        .append("rect")
                        .attr("width", _rectW)
                        .attr("height", _rectH)
                        .attr("id", function (d) {
                            return d.id;
                        })
                        .attr("fill", function (d) {
                            return d.children || d._children || d.hasChild
                                ? "url(#gradientchilds)"
                                : "url(#gradientnochilds)";
                        })
                        .style("cursor", function (d) {
                            return d.children || d._children || d.hasChild
                                ? "pointer"
                                : "default";
                        })
                        .attr("class", "box1");

                    nodeEnter
                        .append("text")
                        .attr("x", _rectW / 2)
                        .attr("y", _rectH / 2)
                        .attr("dy", ".35em")
                        .attr("text-anchor", "middle")
                        .style("cursor", function (d) {
                            return d.children || d._children || d.hasChild
                                ? "pointer"
                                : "default";
                        })
                        .text(function (d) {
                            return d.firstName + " " + d.lastName;
                        });

                    // Transition nodes to their new position.
                    var nodeUpdate = node
                        .transition()
                        .duration(_duration)
                        .attr("transform", function (d) {
                            return "translate(" + d.x + "," + d.y + ")";
                        });

                    nodeUpdate.select("rect.box1").attr("fill", function (d) {
                        return d.children || d._children || d.hasChild
                            ? "url(#gradientchilds)"
                            : "url(#gradientnochilds)";
                    });

                    // Transition exiting nodes to the parent's new position.
                    var nodeExit = node
                        .exit()
                        .transition()
                        .duration(_duration)
                        .attr("transform", function (d) {
                            return (
                                "translate(" + source.x + "," + source.y + ")"
                            );
                        })
                        .remove();

                    // Update the links
                    var link = _svg
                        .selectAll("path.link")
                        .data(links, function (d) {
                            return d.target.id;
                        });

                    if (_mode === "line") {
                        // Enter any new links at the parent's previous position.
                        link.enter()
                            .append("path", "g")
                            .attr("class", "link")
                            .attr("d", function (d) {
                                var u_line = (function (d) {
                                    var u_linedata = [
                                        {
                                            x:
                                                d.source.x0 +
                                                parseInt(_rectW / 2),
                                            y: d.source.y0 + _rectH + 2,
                                        },
                                        {
                                            x:
                                                d.source.x0 +
                                                parseInt(_rectW / 2),
                                            y: d.source.y0 + _rectH + 2,
                                        },
                                        {
                                            x:
                                                d.source.x0 +
                                                parseInt(_rectW / 2),
                                            y: d.source.y0 + _rectH + 2,
                                        },
                                        {
                                            x:
                                                d.source.x0 +
                                                parseInt(_rectW / 2),
                                            y: d.source.y0 + _rectH + 2,
                                        },
                                    ];

                                    return u_linedata;
                                })(d);

                                return _lineFunction(u_line);
                            });

                        // Transition links to their new position.
                        link.transition()
                            .duration(_duration)
                            .attr("d", function (d) {
                                var u_line = (function (d) {
                                    var u_linedata = [
                                        {
                                            x:
                                                d.source.x +
                                                parseInt(_rectW / 2),
                                            y: d.source.y + _rectH,
                                        },
                                        {
                                            x:
                                                d.source.x +
                                                parseInt(_rectW / 2),
                                            y: d.target.y - _margin.top / 2,
                                        },
                                        {
                                            x:
                                                d.target.x +
                                                parseInt(_rectW / 2),
                                            y: d.target.y - _margin.top / 2,
                                        },
                                        {
                                            x:
                                                d.target.x +
                                                parseInt(_rectW / 2),
                                            y: d.target.y,
                                        },
                                    ];

                                    return u_linedata;
                                })(d);

                                return _lineFunction(u_line);
                            });

                        // Transition exiting nodes to the parent's new position.
                        link.exit()
                            .transition()
                            .duration(_duration)
                            .attr("d", function (d) {
                                /*
                                 * This is needed to draw the lines
                                 * right back to the caller
                                 */
                                var u_line = (function (d) {
                                    var u_linedata = [
                                        {
                                            x:
                                                _callerNode.x +
                                                parseInt(_rectW / 2),
                                            y: _callerNode.y + _rectH + 2,
                                        },
                                        {
                                            x:
                                                _callerNode.x +
                                                parseInt(_rectW / 2),
                                            y: _callerNode.y + _rectH + 2,
                                        },
                                        {
                                            x:
                                                _callerNode.x +
                                                parseInt(_rectW / 2),
                                            y: _callerNode.y + _rectH + 2,
                                        },
                                        {
                                            x:
                                                _callerNode.x +
                                                parseInt(_rectW / 2),
                                            y: _callerNode.y + _rectH + 2,
                                        },
                                    ];

                                    return u_linedata;
                                })(d);

                                return _lineFunction(u_line);
                            })
                            .each("end", function () {
                                _callerNode = null;
                                /*
                                 * After
                                 * transition
                                 * clear
                                 * the
                                 * caller
                                 * node
                                 * variable
                                 */
                            });
                    } else if (_mode === "diagonal") {
                        // Enter any new links at the parent's previous position.
                        link.enter()
                            .insert("path", "g")
                            .attr("class", "link")
                            .attr("x", _rectW / 2)
                            .attr("y", _rectH / 2)
                            .attr("d", function (d) {
                                var o = {
                                    x: source.x0,
                                    y: source.y0,
                                };
                                return _diagonal({
                                    source: o,
                                    target: o,
                                });
                            });

                        // Transition links to their new position.
                        link.transition()
                            .duration(_duration)
                            .attr("d", _diagonal);

                        // Transition exiting nodes to the parent's new position.
                        link.exit()
                            .transition()
                            .duration(_duration)
                            .attr("d", function (d) {
                                var o = {
                                    x: source.x,
                                    y: source.y,
                                };
                                return _diagonal({
                                    source: o,
                                    target: o,
                                });
                            })
                            .remove();
                    }

                    // Stash the old positions for transition.
                    _nodes.forEach(function (d) {
                        d.x0 = d.x;
                        d.y0 = d.y;
                    });
                },
                // Toggle children on click.
                nodeclick = function (d) {
                    if (!d.children && !d._children && d.hasChild) {
                        // If there are no childs --> Try to load child nodes
                        _loadFunction(d, function (childs) {
                            var response = {
                                id: d.id,
                                login: d.login,
                                children: childs.result,
                            };

                            response.children.forEach(function (child) {
                                if (!_tree.nodes(d)[0]._children) {
                                    _tree.nodes(d)[0]._children = [];
                                }

                                child.x = d.x;
                                child.y = d.y;
                                child.x0 = d.x0;
                                child.y0 = d.y0;
                                _tree.nodes(d)[0]._children.push(child);
                            });

                            if (d.children) {
                                _callerNode = d;
                                _callerMode = 0; // Collapse
                                d._children = d.children;
                                d.children = null;
                            } else {
                                _callerNode = null;
                                _callerMode = 1; // Expand
                                d.children = d._children;
                                d._children = null;
                            }

                            update(d);
                        });
                    } else {
                        if (d.children) {
                            _callerNode = d;
                            _callerMode = 0; // Collapse
                            d._children = d.children;
                            d.children = null;
                        } else {
                            _callerNode = d;
                            _callerMode = 1; // Expand
                            d.children = d._children;
                            d._children = null;
                        }

                        update(d);
                    }
                },
                // Redraw for zoom
                redraw = function () {
                    _svg.attr(
                        "transform",
                        "translate(" +
                            d3.event.translate +
                            ")" +
                            " scale(" +
                            d3.event.scale.toFixed(1) +
                            ")"
                    );
                },
                initTree = function (options) {
                    var u_opts = $.extend(
                            {
                                id: "",
                                data: {},
                                modus: "line",
                                loadFunc: function () {},
                            },
                            options
                        ),
                        id = u_opts.id;

                    _loadFunction = u_opts.loadFunc;
                    _mode = u_opts.modus;
                    _root = u_opts.data;

                    if (_mode == "line") {
                        _fixedDepth = 80;
                    } else {
                        _fixedDepth = 110;
                    }

                    $(id).html(""); // Reset

                    var width =
                            $(id).innerWidth() - _margin.left - _margin.right,
                        height =
                            $(id).innerHeight() - _margin.top - _margin.bottom;

                    //    var width = 1050;
                    //    var height = 1500;

                    _tree = d3.layout
                        .tree()
                        .nodeSize([
                            _rectW + _rectSpacing,
                            _rectH + _rectSpacing,
                        ]);

                    /* Basic Setup for the diagonal function. _mode = "diagonal" */
                    _diagonal = d3.svg.diagonal().projection(function (d) {
                        return [d.x + _rectW / 2, d.y + _rectH / 2];
                    });

                    /* Basic setup for the line function. _mode = "line" */
                    _lineFunction = d3.svg
                        .line()
                        .x(function (d) {
                            return d.x;
                        })
                        .y(function (d) {
                            return d.y;
                        })
                        .interpolate("linear");

                    var u_childwidth = parseInt(
                        (_root.children.length * _rectW) / 2
                    );
                    var zm;
                    _svgroot = d3
                        .select(id)
                        .append("svg")
                        .attr("width", width)
                        .attr("height", height)
                        .call(
                            (zm = d3.behavior
                                .zoom()
                                .scaleExtent([0.15, 3])
                                .on("zoom", redraw))
                        );

                    _svg = _svgroot
                        .append("g")
                        .attr(
                            "transform",
                            "translate(" +
                                parseInt(
                                    u_childwidth +
                                        (width - u_childwidth * 2) / 2 -
                                        _margin.left / 2
                                ) +
                                "," +
                                20 +
                                ")"
                        )
                        .attr("shape-rendering", "geometricPrecision");

                    var u_stops = [
                        { offset: "0%", color: "#03A9F4", opacity: 1 },
                        { offset: "100%", color: "#0288D1", opacity: 1 },
                    ];
                    defLinearGradient(
                        "gradientnochilds",
                        "0%",
                        "0%",
                        "0%",
                        "100%",
                        u_stops
                    );
                    var u_stops = [
                        { offset: "0%", color: "#00e658", opacity: 1 },
                        { offset: "100%", color: "#048033", opacity: 1 },
                    ];
                    defLinearGradient(
                        "gradientchilds",
                        "0%",
                        "0%",
                        "0%",
                        "100%",
                        u_stops
                    );

                    defBoxShadow("boxShadow");

                    // necessary so that zoom knows where to zoom and unzoom from
                    zm.translate([
                        parseInt(
                            u_childwidth +
                                (width - u_childwidth * 2) / 2 -
                                _margin.left / 2
                        ),
                        20,
                    ]);

                    _root.x0 = 0; // the root is already centered
                    _root.y0 = height / 2; // draw & animate from center

                    _root.children.forEach(collapse);
                    update(_root);

                    d3.select(id).style(
                        "height",
                        height + _margin.top + _margin.bottom
                    );
                    svgUH = _svgroot;
                    svgUHwidth = width;
                    svgUHheigth = height;
                };

            return { initTree: initTree };
        })();

        var u_data = {};

        function loadChilds(actualElement, successFunction) {
            UsersHierarchy.getUsersHierarchy(
                actualElement.login,
                function (response) {
                    var result = { result: response.children };
                    successFunction(result);
                }
            );
        }

        // treeeeeeeeeeeeee------------------------------------------///

        function downloadUserManagementUserList() {
            // DownloadCaseReports.download(function (response) {
            var a = document.createElement("a");
            a.style = "display: none";
            var file = new Blob([response], { type: "application/csv" });
            var fileURL = URL.createObjectURL(file);
            // window.open(fileURL, "_blank");
            a.href = fileURL;
            a.download = "case_report.csv";
            a.click();
            window.URL.revokeObjectURL(fileURL);
            // });
        }

        function downloadUsers() {
            DownloadUsers.download(function (response) {
                var a = document.createElement("a");
                a.style = "display: none";
                var file = new Blob([response], { type: "application/csv" });
                var fileURL = URL.createObjectURL(file);
                a.href = fileURL;
                a.download = "users.csv";
                a.click();
                window.URL.revokeObjectURL(fileURL);
            });
        }
    }
})();
